<template>
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12" md="8" lg="6">
          <v-card class="pa-5">
            <v-card-title class="headline">Confirmación de Pedido</v-card-title>
            <v-card-text class="mt-4">
              <v-icon x-large left color="success">mdi-check-circle-outline</v-icon>
              <span class="headline">¡Pedido realizado con éxito!</span>
              <p class="mt-4">Hemos recibido tu pedido y estamos procesándolo. En breve recibirás un correo de confirmación con los detalles del pedido y las instrucciones de pago.</p>
              <p class="mt-4">Si tienes alguna pregunta, no dudes en <a href="/contactar">contactarnos</a>.</p>
            </v-card-text>
            <v-card-actions class="justify-center mt-4">
              <v-btn color="primary" @click="$router.push('/')">Volver al Inicio</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'ConfirmationPage',
  }
  </script>
  
  <style scoped>
  .headline {
    font-weight: bold;
    text-align: center;
  }
  </style>