<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" md="10" lg="8">

                <h1>Política de Privacidad de TFG Assistant</h1>

                <v-img
                    src="/imagenes/web/Politica-de-Privacidad.jpg"
                    alt="Documento resaltando los términos de la política de privacidad de TFG Assistant"
                    title="Política de Privacidad de TFG Assistant"
                    aspect-ratio="1.5"
                ></v-img>

                <section>
                    <h2>Introducción</h2>
                    <p>Bienvenido a TFG Assistant, su aliado confiable en la elaboración de Trabajos Finales de Grado (TFG) y Trabajos Finales de Máster (TFM). Nos comprometemos a proteger su privacidad y asegurar que toda la información personal y académica que nos proporciona permanezca completamente anónima y segura. Esta política de privacidad detalla cómo recopilamos, utilizamos y protegemos su información, garantizando total transparencia y anonimato.</p>
                </section>

                <section>
                    <h2>Recolección de Información</h2>
                    <h3>¿Qué tipo de información recopilamos?</h3>
                    <p>Cuando utiliza TFG Assistant, recopilamos información que nos proporciona directamente al ingresar sus detalles en el sistema. Esta información puede incluir, pero no se limita a, su área de estudio, directrices del proyecto y cualquier requisito específico que nos indique para la realización de su trabajo. Sin embargo, bajo ninguna circunstancia solicitamos o almacenamos datos personales que puedan identificarle directamente.</p>
                </section>

                <section>
                    <h2>Uso de la Información</h2>
                    <h3>¿Cómo utilizamos su información?</h3>
                    <p>La información que recopilamos se utiliza exclusivamente para el propósito de generar y mejorar los trabajos académicos solicitados a través de TFG Assistant. No compartimos, vendemos ni divulgamos su información a terceros.</p>
                </section>

                <section>
                    <h2>Anonimato Garantizado</h2>
                    <h3>¿Cómo garantizamos su anonimato?</h3>
                    <p>TFG Assistant está diseñado con un enfoque en la privacidad desde la conceptualización. Implementamos avanzadas medidas de seguridad para asegurar que toda la información que procesamos es anonimizada antes de ser usada en nuestro sistema. Esto significa que incluso cuando se genera un documento académico, no hay posibilidad de rastrear dicha información hasta llegar a usted como individuo.</p>
                </section>

                <section>
                    <h2>Seguridad de la Información</h2>
                    <h3>Medidas de seguridad que tomamos</h3>
                    <p>Tomamos la seguridad de la información muy seriamente. Utilizamos tecnologías de encriptación de vanguardia y protocolos de seguridad robustos para proteger la información que almacenamos y procesamos. Además, realizamos auditorías regulares y pruebas de seguridad para fortalecer nuestras defensas contra accesos no autorizados y otros posibles riesgos de seguridad.</p>
                </section>

                <section>
                    <h2>Sus Derechos</h2>
                    <h3>Control sobre su información</h3>
                    <p>Usted tiene el control total sobre la información que nos proporciona y puede solicitar en cualquier momento que eliminen cualquier dato relacionado con su perfil. También tiene derecho a solicitar una copia de la información que hemos recopilado sobre su proyecto para revisarla o corregirla según sea necesario.</p>
                </section>

                <section>
                    <h2>Cambios en la Política de Privacidad</h2>
                    <p>Este documento puede actualizarse para reflejar cambios en nuestras prácticas de privacidad. Cualquier cambio significativo en la forma en que manejamos la información personal será comunicado a través de nuestra plataforma con anticipación suficiente para ofrecerle oportunidad de revisar los cambios.</p>
                </section>

                <section>
                    <h2>Contacto</h2>
                    <h3>¿Cómo puede contactarnos para más información sobre nuestra política de privacidad?</h3>
                    <p>Si tiene preguntas sobre esta política de privacidad o sobre la seguridad de su información en TFG Assistant, no dude en contactarnos a través de nuestra sección de contacto en el sitio web.</p>
                </section>

                <footer>
                    <p>Al continuar utilizando TFG Assistant, acepta los términos y condiciones establecidos en esta Política de Privacidad. Nos comprometemos a mantener su anonimato y la seguridad de su información en todo momento, permitiéndole concentrarse en lo que realmente importa: la calidad y el éxito de su trabajo académico.</p>
                </footer>

                </v-col>
            </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'App',

    // ESTE ES UN BUEN EJEMPLO DE METAS A COPIAR EN TODAS LAS PAGINAS
    metaInfo: {
      title: 'Política de Privacidad - TFG Assistant',
      meta: [
          { charset: 'UTF-8' },
          { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
          { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
          { name: 'description', content: 'Descubre cómo TFG Assistant garantiza la privacidad y el anonimato en la elaboración de trabajos finales de grado y máster.' },
          { name: 'keywords', content: 'TFG Assistant, Política de Privacidad, Anonimato Garantizado, Seguridad de Información, Privacidad en Academia' },
          { name: 'author', content: 'TFG Assistant' },
          { name: 'robots', content: 'index, follow' },
          { name: 'googlebot', content: 'index, follow' },
         // { property: 'og:title', content: 'Política de Privacidad - TFG Assistant' },
         // { property: 'og:description', content: 'Descubre cómo TFG Assistant garantiza la privacidad y el anonimato en la elaboración de trabajos finales de grado y máster.' },
         // { property: 'og:type', content: 'website' },
         // { property: 'og:url', content: 'https://www.tfgassistant.com/privacidad' },
         // { property: 'og:image', content: 'https://www.tfgassistant.com/images/privacy-policy-banner.jpg' }
      ]
  },

  };
  </script>