<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="10" lg="8">
        <h1>Página de Ejemplos</h1>
        <p>En TFG Assistant, nos enorgullece la capacidad de nuestro sistema avanzado de IA para generar trabajos académicos originales, bien investigados y desde múltiples perspectivas. A continuación, encontrarás ejemplos de cómo TFG Assistant puede abordar diferentes temas desde distintos ángulos, demostrando nuestra habilidad para adaptarnos a diversas necesidades y enfoques académicos.</p>

        <h2>Ejemplos de Trabajos</h2>

        <v-list>
          <!-- Ejemplo 1 -->
          <v-list-item>
            <v-list-item-content>
              <p><strong>Ejemplo 1: Fundamentos y Teorías de la Administración y Dirección de Empresas</strong></p>
              <p><strong>Carrera:</strong> Administración y Dirección de Empresas</p>
              <p><strong>Resumen:</strong> Este documento examina los fundamentos y teorías clave en la administración y dirección de empresas, proporcionando una comprensión holística desde los enfoques clásicos a los contemporáneos.</p>
              <p><strong>Extracto del Contenido:</strong></p>
              <blockquote>
                "La administración puede definirse como el proceso de diseñar y mantener un entorno en el que, trabajando en grupos, los individuos cumplan eficientemente objetivos específicos (Koontz y Weihrich, 1998). Esta definición destaca la importancia del proceso administrativo, el trabajo en equipo y la orientación hacia objetivos claros."
              </blockquote>
              <p><strong>Informe de Plagio:</strong></p>
              <v-progress-linear :value="0.5" color="green"></v-progress-linear> 0.5%
              <p><a href="https:\\www.tfgassistant.com\ejemplos\Fundamentos_y_Teorías_de_la_Administración_y_Dirección_de_Empresas.docx" download>Descargar Archivo de Word</a></p>
              <p><a href="https:\\www.tfgassistant.com\ejemplos\Fundamentos_y_Teorías_de_la_Administración_y_Dirección_de_Empresas.pdf" target="_blank">Ver Informe de Plagio (PDF)</a></p>
              <p><strong>Informe de Detector de IA:</strong></p>
              <v-progress-linear :value="88" color="blue"></v-progress-linear> 88% Humano
            </v-list-item-content>
          </v-list-item>

          <v-divider
            :inset="true"
            class="my-5"
            color="deep-purple accent-4"
            :thickness="5"
          ></v-divider>

          <!-- Ejemplo 2 -->
          <v-list-item>
            <v-list-item-content>
              <p><strong>Ejemplo 2: El derecho a la vida</strong></p>
              <p><strong>Carrera:</strong> Derecho</p>
              <p><strong>Resumen:</strong> Este ensayo analiza el derecho a la vida a través de las lentes del derecho, abarcando su origen, historia, conceptos y regulaciones, así como los mecanismos de protección existentes.</p>
              <p><strong>Extracto del Contenido:</strong></p>
              <blockquote>
                "El derecho a la vida es el sustento ontológico del resto de derechos y está directamente relacionado con la dignidad humana (Tribunal Constitucional de España, Sentencia 53/1985)."
              </blockquote>
              <p><strong>Informe de Plagio:</strong></p>
              <v-progress-linear :value="5" color="green"></v-progress-linear> 5%
              <p><a href="https:\\www.tfgassistant.com\ejemplos\EL_DERECHO_A_LA_VIDA.docx" target="_blank">Descargar Archivo de Word</a></p>
              <p><a href="https:\\www.tfgassistant.com\ejemplos\EL_DERECHO_A_LA_VIDA.pdf" target="_blank">Ver Informe de Plagio (PDF)</a></p>
              <p><strong>Informe de Detector de IA:</strong></p>
              <v-progress-linear :value="86" color="blue"></v-progress-linear> 86% Humano
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider
          :inset="true"
          class="my-5"
          color="deep-purple accent-4"
          :thickness="5"
        ></v-divider>


        <h2>¿Por Qué Elegir TFG Assistant?</h2>
        <p>Estos ejemplos ilustran la profundidad y amplitud de análisis que nuestro asistente de IA puede ofrecer, demostrando que cada trabajo es único y adaptado a los requerimientos específicos de cada cliente. Con TFG Assistant, te aseguras no solo originalidad y conformidad con los estándares académicos, sino también una herramienta poderosa capaz de apoyarte en la creación de trabajos rigurosos y bien fundamentados.</p>

        <h2>Inicia Tu Proyecto</h2>
        <p>Si estás impresionado con lo que TFG Assistant puede hacer por ti, no dudes en <a href="/formulario-compra">comenzar tu proyecto con nosotros hoy</a>. ¡Estamos aquí para ayudarte a alcanzar tus metas académicas con profesionalismo y precisión!</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'App',

  data() {
    return {
      adminExcerpt: `
        "La administración puede definirse como 'el proceso de diseñar y mantener un entorno en el que, trabajando en grupos, los individuos cumplan eficientemente objetivos específicos' (Koontz y Weihrich, 1998)."
      `,
      derechoExcerpt: `
        "El derecho a la vida es el sustento ontológico del resto de derechos y está directamente relacionado con la dignidad humana (Tribunal Constitucional español, Sentencia 53/1985)."
      `
    };
  },

  metaInfo: {
    title: '¡NOVEDAD! Ejemplos de TFG / TFM redactados por TFG Assistant',
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: 'Aquí tienes ejemplos de como nuestro modelo entrenado de GPT-4 de OpenAI redactado TFG y TFM con calidad, sin plagio y bien citados.' },
      { name: 'keywords', content: 'ejemplos TFG, ejemplos TFM, ejemplos TFG Assistant' },
      { name: 'robots', content: 'index, follow' },
    ]
  },
};
</script>



