<template>

    <v-card>
      <v-card-title>
        Diseñador de Índices para TFG / TFM
      </v-card-title>
      <v-card-text>
        <!-- Nuevo texto explicativo -->
        <p>Seleccione los apartados del lado izquierdo para añadirlos a la lista de la derecha, que representa la estructura del índice de su trabajo. No importa el orden en el que se añade el apartado, se organizan automaticamente en el proceso de generación.</p>
        
        <v-row>
          <!-- Columna de elementos predefinidos -->
          <v-col cols="12" md="5">
            <v-card outlined class="elevation-2">
              <v-card-title class="text-h6">Apartados</v-card-title>
              <v-list dense class="bordered-list">
                <v-list-item
                  v-for="item in defaultIndices"
                  :key="item"
                  @click="selectItem(item, 'available')"
                  :class="{ 'selected-item': selectedAvailableItem === item }"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
  
          <!-- Botones añadir y eliminar -->
          <v-col cols="12" md="2" class="d-flex flex-column align-center justify-center">
            <v-btn icon @click="addSelected" :disabled="!selectedAvailableItem" color="primary">
              <v-icon>mdi-arrow-right-bold</v-icon>
            </v-btn>
            <v-btn icon @click="removeSelected" :disabled="selectedSelectedItem === null" color="error" class="mt-2">
              <v-icon>mdi-arrow-left-bold</v-icon>
            </v-btn>
          </v-col>
  
          <!-- Columna de elementos seleccionados -->
          <v-col cols="12" md="5">
            <v-card outlined class="elevation-2">
              <v-card-title class="text-h6">Mi Índice</v-card-title>
              <v-list dense class="bordered-list">
                <v-list-item
                  v-for="(item, index) in indices"
                  :key="index"
                  @click="selectItem(index, 'selected')"
                  :class="{ 'selected-item': selectedSelectedItem === index }"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
  
          <!-- Campo de texto para añadir elementos personalizados -->
          <v-col cols="12" md="8">
            <v-text-field label="Añadir apartado Personalizado" v-model="customItem" dense></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn small @click="addCustomIndex" color="success">Añadir</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </template>
  
<script>
  export default {
    props: {
      value: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        defaultIndices: [
          "Agradecimientos",
          "Análisis",
          "Anexos",
          "Apéndices",
          "Bibliografía",
          "Conclusiones",
          "Discusión",
          "Glosario",
          "Introducción",
          "Marco Teórico",
          "Metodología",
          "Referencias",
          "Resultados",
          "Resumen",
        ],
        indices: [
          "Introducción",
          "Metodología",
          "Bibliografía",
          "Conclusiones",          
        ],
        customItem: '',
        selectedAvailableItem: null,
        selectedSelectedItem: null,
      };
    },
    watch: {
      indices: {
        handler(val) {
          this.$emit('input', val.join(', '));
        },
        deep: true
      }
    },
    methods: {

      updateValue(newValue) {
        this.$emit('input', newValue); // Emite el valor hacia el componente padre
      },
      selectItem(item, type) {
        if (type === 'available') {
          this.selectedAvailableItem = this.selectedAvailableItem === item ? null : item;
        } else if (type === 'selected') {
          this.selectedSelectedItem = this.selectedSelectedItem === item ? null : item;
        }
      },
      addSelected() {
        if (this.selectedAvailableItem) {
          if (!this.indices.includes(this.selectedAvailableItem)) {
            this.indices.push(this.selectedAvailableItem);
          }
          this.selectedAvailableItem = null;
        }
      },
      removeSelected() {
        if (this.selectedSelectedItem != null) {
          this.indices.splice(this.selectedSelectedItem, 1);
          this.selectedSelectedItem = null;
        }
      },
      addCustomIndex() {
        if (this.customItem && !this.indices.includes(this.customItem)) {
          this.indices.push(this.customItem);
          this.customItem = '';
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .selected-item {
    background-color: #1E88E5;
  }
  
  .bordered-list {
    border: 1px solid #1E88E5;
  }
  </style>