<template>
    <v-container>
      <v-row justify="center">
          <v-col cols="12" md="10" lg="8">
          
            <h1>Política de Cookies</h1>

            <h2>1. Introducción</h2>
            <p>En nuestra página web, utilizamos cookies para mejorar la experiencia del usuario. Al utilizar nuestra web, aceptas el uso de cookies de acuerdo con esta política.</p>

            <h2>2. ¿Qué son las cookies?</h2>
            <p>Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo cuando visitas una página web. Estas permiten a la web recordar tus acciones y preferencias durante un período de tiempo.</p>

            <h2>3. Datos que recogemos</h2>
            <p>En nuestra página web, solo almacenamos la siguiente información:</p>
            <ul>
                <li>Nombre (no tiene por qué ser real)</li>
                <li>Correo electrónico</li>
                <li>Datos relacionados con el nombre de una carrera o similares</li>
            </ul>

            <h2>4. ¿Cómo utilizamos las cookies?</h2>
            <p>Las cookies en nuestra web se utilizan para:</p>
            <ul>
                
                <li>Mejorar la funcionalidad del sitio web</li>
            </ul>

            <h2>5. Gestión de cookies</h2>
            <p>Puedes controlar y/o eliminar las cookies como desees. Para más información sobre cómo hacerlo, visita <a href="https://www.aboutcookies.org/">aboutcookies.org</a>. Puedes eliminar todas las cookies que ya están en tu computadora y configurar la mayoría de los navegadores para impedir que se almacenen. Si haces esto, es posible que tengas que ajustar manualmente algunas preferencias cada vez que visites un sitio y que algunos servicios y funcionalidades no funcionen.</p>

            <h2>6. Cambios en la política de cookies</h2>
            <p>Podemos actualizar nuestra política de cookies ocasionalmente. Te recomendamos revisar esta página regularmente para estar informado sobre cualquier cambio.</p>

            <h2>7. Contacto</h2>
            <p>Si tienes alguna pregunta sobre nuestra política de cookies, puedes contactarnos a través de nuestro correo electrónico de soporte.</p>
      
            
          </v-col>
        </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'App',
  
    metaInfo: {
        title: 'TFG ASSISTANT - Politica de cookies',
        meta: [
          { charset: 'utf-8' },
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          
          { name: 'description', content: 'Politica de cookies.' },
       //   { name: 'keywords', content: 'ejemplos TFG, ejemplos TFM, ejemplos TFG Assistant' },
          // Robots
          { name: 'robots', content: 'noindex' },
          // Open Graph / Facebook
          //{ property: 'og:title', content: 'El título de tu página' },
          //{ property: 'og:site_name', content: 'El nombre de tu sitio' },
          //{ property: 'og:description', content: 'Descripción de tu página.' },
          //{ property: 'og:type', content: 'website' },
          //{ property: 'og:image', content: 'URL de tu imagen destacada' },
          // Twitter Card
          //{ name: 'twitter:card', content: 'summary_large_image' },
          //{ name: 'twitter:title', content: 'El título de tu página' },
          //{ name: 'twitter:description', content: 'Descripción de tu página.' },
          //{ name: 'twitter:image', content: 'URL de tu imagen destacada' },
        ]
      },
  };
  </script>