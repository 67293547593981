<template>
  <div class="container">
    <h1>Página de Confirmación</h1>

    <!-- Mensaje de respuesta del servidor con estilo dinámico -->
    <div v-if="responseMessage" :class="responseClass">
        {{ responseMessage }}
    </div>
    
    <!-- Mostrar el código de pedido si está disponible -->
    <p v-if="orderCode">Código de identificación: {{ orderCode }}</p>
    
    <!-- Botón para regresar al inicio o a la página de productos -->
    <button @click="regresarAlInicio">Regresar al Inicio</button>
  </div>
</template>

<script>
import axios from 'axios'; // Asegúrate de tener Axios instalado

export default {
  name: 'PedidoExitoso',

  data() {
    return {
      responseMessage: "",
      responseSuccess: false,
      orderCode: null,
      responseClass: ""
    }
  },
  metaInfo: {
    meta: [
      { vmid: 'robots', name: 'robots', content: 'noindex, nofollow' }
    ]
  },
  
  mounted() {
    const numeroPedido = this.$route.query.idP;
    this.orderCode = numeroPedido;
    
    if (numeroPedido) {
      this.enviarNumeroPedidoAPHP(numeroPedido);
    }
  },

  methods: {
    enviarNumeroPedidoAPHP(numeroPedido) {
      axios.post('/api/actualizarPedido.php', {
        idP: numeroPedido,
        pagado: 'si'
      })
      .then(response => {
        console.log("Respuesta del servidor:", response.data);
        this.responseMessage = "¡Tu pedido ha sido procesado con éxito!";
        this.responseSuccess = true;
        this.setResponseClass();
      })
      .catch(error => {
        console.error("Error al enviar el número de pedido:", error);
        this.responseMessage = "Ocurrió un error al procesar tu pedido. Envíanos un correo indicando el código de identificación.";
        this.responseSuccess = false;
        this.setResponseClass();
      });
    },
    setResponseClass() {
      this.responseClass = this.responseSuccess ? 'message success' : 'message error';
    },
    regresarAlInicio() {
      // Redireccionar al usuario al inicio o a la página de productos
      this.$router.push('/');
    }
  }
}
</script>

<style>
/* Estilos básicos */
.container {
  margin: 20px;
  text-align: justify ;
}

.message {
  margin: 20px auto;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
}

.success {
  background-color: #4CAF50; /* Verde */
}

.error {
  background-color: #f44336; /* Rojo */
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #008CBA; /* Azul */
  color: white;
  border: none;
  border-radius: 5px;
}

.bottom-nav {
  display: flex;
  justify-content: center; /* Centra elementos horizontalmente */
  align-items: center; /* Centra elementos verticalmente */
  height: 60px; /* Define una altura, ajusta según necesites */
  
  /* Si deseas asegurarte de que se adhiera al fondo, puedes utilizar */
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000; /* Asegúrate de que esté encima de otros elementos si es necesario */
}

/* Para centrar los íconos y textos dentro de cada botón */
.bottom-nav .v-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-desktop .v-container {
  /* Asegura que el contenedor interno use todo el espacio disponible */
  height: 100%;
}

.footer-desktop .v-row {
  /* Uso de Flexbox para centrar el contenido en su contenedor */
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  text-align: center; /* Asegura que el texto también esté centrado */
}

/* Si necesitas centrar además los íconos dentro de los párrafos */
.footer-desktop p {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-desktop v-icon {
  margin-right: 8px; /* Espaciado entre el icono y el texto, ajusta según necesites */
}
</style>