import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueMeta from 'vue-meta';
import '@babel/polyfill';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

// Configuración de Vue
Vue.config.productionTip = false;
Vue.use(VueMeta);

// Crea y monta la instancia de Vue
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');