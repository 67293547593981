<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="10" lg="8">
        <h1>Nuestros precios</h1>

        <p>En TFG Assistant, entendemos que cada trabajo académico tiene sus propias necesidades y requerimientos. Por ello, nuestro objetivo es ofrecer una estructura de precios clara, justa y ventajosa para todos nuestros clientes, sean estudiantes o profesionales dedicados a la redacción académica. A continuación, encontrarás los detalles de nuestros paquetes y las tarifas aplicables.</p>
        
        <h2>Paquetes de Servicios</h2>
        <p>Te ofrecemos una variedad de paquetes basados en el número de páginas y el tiempo de entrega, diseñados para cubrir todas las necesidades académicas y presupuestos, garantizando siempre un trabajo de calidad superior:</p>

        <v-row>
          <v-col v-for="(item, index) in prices" :key="index" cols="12" md="6">
            <v-card class="mx-auto pa-4 custom-card" max-width="400">
              <v-card-title class="text-center font-weight-bold custom-card-title">{{ item.pages }}</v-card-title>
              <v-divider></v-divider>
              <v-card-subtitle class="custom-card-subtitle">
                <v-simple-table dense class="custom-table">
                  <tbody>
                    <tr>
                      <th class="text-center">Entrega Estándar</th>
                      <th class="text-center">OFERTA</th>
                    </tr>
                    <tr>
                      <td class="font-weight-bold text-center standard-price">
                        <s>{{ item.standard }}</s>
                      </td>
                      <td class="font-weight-bold text-center oferta-price">
                        {{ calculateDiscount(item.standard) }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>

                <v-divider></v-divider>

                <v-simple-table dense class="custom-table">
                  <tbody>
                    <tr class="urgent-price">
                      <th class="text-center">Entrega Urgente</th>
                      <th class="text-center">OFERTA</th>
                    </tr>
                    <tr class="urgent-price">
                      <td class="font-weight-bold text-center standard-price">
                        <s>{{ item.urgent }}</s>
                      </td>
                      <td class="font-weight-bold text-center oferta-price">
                        {{ calculateDiscount(item.urgent) }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>

                <v-divider></v-divider>
              </v-card-subtitle>
              <v-card-actions class="justify-center custom-card-actions">
                <v-btn color="warning" dark class="ml-4" to="/formulario-compra">
                  <span style="font-weight: bold;">COMPRAR</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <h2>Plazos de entrega</h2>
        <ul>
          <li>Estándar (entregas en menos de 5 días).</li>
          <li>Urgente (entregas en menos de 48 horas).</li>
        </ul>

        <h3>Cada paquete garantiza:</h3>
        <ul>
          <li><strong>Redacción de Alta Calidad:</strong> Contenido completamente desarrollado, coherente y bien estructurado.</li>
          <li><strong>Originalidad y Seguridad:</strong> Todos los documentos son redactados desde cero para evitar cualquier forma de plagio y son generados de manera que no son detectados por sistemas anti-IA.</li>
          <li><strong>Respeto por Normativas Académicas:</strong> Total adherencia a las directrices académicas, incluyendo el formateo y las referencias.</li>
        </ul>

        <h2>Simplicidad en el Proceso de Compra</h2>
        <ol>
          <li><strong>Elige tu Paquete</strong>: Selecciona el paquete más adecuado para tus necesidades académicas.</li>
          <li><strong>Confirma tu Pedido</strong>: Completa el proceso de compra a través de nuestra plataforma segura y fácil de usar.</li>
          <li><strong>Recibe tu Trabajo</strong>: Recibirás tu documento completo, de alta calidad y listo para su presentación, directamente en tu correo electrónico.</li>
        </ol>

        <h2>Compromiso con la Calidad</h2>
        <p>En TFG Assistant, nos comprometemos a ofrecer el más alto nivel de calidad en todos nuestros servicios. Si el documento final no cumple con tus expectativas académicas o los estándares requeridos, ofrecemos la posibilidad de solicitar <strong>revisiones dentro de los 14 días naturales posteriores a la entrega.</strong></p>
        <p>En TFG Assistant, tu éxito académico es nuestra prioridad. Te invitamos a iniciar tu proyecto con nosotros hoy mismo y beneficiarte de un servicio que garantiza la excelencia y la integridad en cada página. ¡Estamos aquí para ayudarte a alcanzar tus metas académicas con confianza y tranquilidad!</p>
        
        <h2>Paquetes Especiales para Redactores Profesionales</h2>
        <p><strong>Programa de Beneficios para Redactores</strong></p>
        <ul>
          <li><strong>Descuento Acumulativo:</strong> Una vez que un redactor supera las 200 páginas contratadas con nosotros en un año, se activa automáticamente un descuento del 20% en todas sus compras futuras para el resto del año. Este beneficio se renueva cada año, siempre que se mantenga el nivel de compra requerido.<strong><a href="/contactar">Contáctanos</a></strong></li>
        </ul>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      prices: [
        { pages: '15 páginas', standard: '150€', urgent: '180€' },
        { pages: '25 páginas', standard: '250€', urgent: '300€' },
        { pages: '35 páginas', standard: '350€', urgent: '420€' },
       // { pages: '40 páginas', standard: '375€', urgent: '480€' },
        { pages: '50 páginas', standard: '475€', urgent: '600€' },
        { pages: '75 páginas', standard: '675€', urgent: '900€' },
        { pages: '100 páginas', standard: '875€', urgent: '1200€' }
      ]
    };
  },
  methods: {
    calculateDiscount(price) {
      // Elimina el símbolo de euro y convierte el precio a número
      const numericPrice = parseFloat(price.replace('€', ''));
      // Calcula el precio con el 60% de descuento
      const discountedPrice = numericPrice * 0.4;
      // Redondea al múltiplo de 5 más cercano
      const roundedPrice = Math.round(discountedPrice / 5) * 5;
      // Retorna el precio con formato
      return `${roundedPrice}€`;
    }
  },
  name: 'App',
  metaInfo: {
    title: '¡NOVEDAD! Ejemplos de TFG / TFM redactados por TFG Assistant',
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: 'Aquí tienes ejemplos de como nuestro modelo entrenado de GPT-4 de OpenAI redactado TFG y TFM con calidad, sin plagio y bien citados.' },
      { name: 'keywords', content: 'ejemplos TFG, ejemplos TFM, ejemplos TFG Assistant' },
      { name: 'robots', content: 'index, follow' },
    ]
  },
};
</script>

<style scoped>
.custom-card {
  border: 2px solid #0D47A1 !important; /* Azul más oscuro para el borde */
  background-color: #E3F2FD !important; /* Color de fondo */
  color: #000000 !important; /* Color del texto */
  border-radius: 2px; /* Puede ajustar el radio del borde según lo que desee */
}
.custom-card-title {
  font-weight: bold;
  background-color: #BBDEFB !important; /* Fondo del título de la tarjeta */
  border-bottom: 2px solid #0D47A1 !important; /* Azul más oscuro para el borde inferior del título */
  padding: 16px;
  border-radius: 8px 8px 0 0 !important;
  text-align: center;
}
.custom-card-subtitle {
  padding: 16px;
/*   background-color: inherit !important; Hereda el fondo del v-card */
}
.custom-card-actions {
  padding: 16px;
  justify-content: center;
  background-color: inherit !important; /* Hereda el fondo del v-card */
}
.text-yellow--text {
  color: #ffeb3b !important;
}
.text-h5 {
  font-size: 1.5rem;
  line-height: 1.2;
}

/* Nuevo estilo para tablas con el mismo color de fondo de las tarjetas */
.custom-table {
  background-color: inherit !important; /* Hereda el fondo del contenedor */
  color: #000000 !important; /* Color del texto para evitar discrepancias */
}

.standard-price {
  color: #000000 !important; /* Negro */
}

.oferta-price {
  color: #d32f2f !important; /* Rojo */
  font-size: 1.5rem !important; /* Aumenta el tamaño de la fuente para los precios de oferta */
}

.urgent-price th, .urgent-price td {
  text-align: center;
}
</style>