<template>

    <v-container>
        <v-row justify="center">
            <v-col cols="12" md="10" lg="8">
                
                <h1>Conoce Nuestros Servicios</h1>
                <p>En TFG Assistant, nuestra misión es ofrecerte soluciones innovadoras y personalizadas en redacción académica, ajustadas a tus necesidades y expectativas. Diseñamos nuestros servicios para facilitarte el camino hacia la excelencia académica, minimizando el estrés asociado con la creación de TFGs y TFMs. Descubre a continuación el abanico de servicios que ponemos a tu disposición, cada uno pensado para apoyarte en cada etapa de tu proyecto académico.</p>

                <h2>Redacción Completa del TFG/TFM</h2>

                <p>¿Necesitas un apoyo integral en la elaboración de tu trabajo final? Nuestro servicio de redacción completa es la respuesta perfecta. Con la avanzada tecnología de IA de OpenAI, generamos documentos de alta calidad que cumplen con los estándares académicos de tu carrera y universidad.</p>

                <p><strong>Características del Servicio:</strong></p>
                <ul>
                    <li><strong>Personalización Total:</strong> Tú nos proporcionas la información clave: carrera, especialidad, título del trabajo, estilo de citación, y cualquier detalle específico.<a href="/contactar">Clic aquí para rellenar el tuyo.</a> Nuestro sistema se encarga del resto, adaptando el contenido para satisfacer tus necesidades.</li>
                    <li><strong>Estructura Rigurosa:</strong> Te entregamos un documento organizado meticulosamente que incluye todas las secciones necesarias: Introducción, Marco Teórico, Metodología, Resultados y Conclusión, garantizando que cada parte esté bien fundamentada.</li>
                    <li><strong>Respeto a las Citas y Referencias:</strong> Integración precisa de las bibliografías que proporciones o uso de fuentes académicas reconocidas para asegurar la validez y autenticidad de tu trabajo.</li>
                    <li><strong>Formateo Profesional:</strong> Recibirás el documento en formato Word, con todas las especificaciones técnicas requeridas, listo para revisión final y ajustes personales.</li>
                </ul>

                <p>¿Interesado en <strong>cómo funciona</strong> nuestro proceso? Visita nuestra página de <a href="/como-funciona">Cómo Funciona</a> para más detalles. <br>
                ¿Tienes preguntas o necesitas <strong>asistencia inmediata?</strong> Contáctanos directamente desde nuestra página de <a href="/contactar">Contacto</a>. <br>
                ¿Quieres <strong>ver ejemplos</strong> de nuestro trabajo anterior? Echa un vistazo a algunos de nuestros <a href="/ejemplos">Ejemplos</a> de TFGs y TFMs entregados a clientes satisfechos.</p>

                <p>¡Déjanos ayudarte a alcanzar tus metas académicas con el mínimo esfuerzo y la máxima calidad!</p>

            </v-col>
        </v-row>
    </v-container>
</template>


<script>



export default {
    name: "Servicios",
    
}

</script>
