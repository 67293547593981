<template>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="10" lg="8">
          <h1>Términos y Condiciones de Uso de TFG Assistant</h1>
      
          <p>Bienvenido a TFG Assistant. Al utilizar nuestros servicios, usted acepta cumplir con los siguientes términos y condiciones. Por favor, léalos cuidadosamente antes de usar el servicio.</p>

          <h2>1. Condiciones de Servicio</h2>
          <p>TFG Assistant proporciona servicios de asistencia para la realización de trabajos finales de grado y máster, haciendo uso de tecnología de inteligencia artificial avanzada.</p>

          <h2>2. Política de Devoluciones y Reembolsos</h2>
          <p>Nos comprometemos a proporcionar servicios que cumplan con las especificaciones proporcionadas por nuestros usuarios. En caso de que los trabajos entregados no cumplan con las indicaciones ofrecidas:</p>
          <ul>
          <li>El usuario tiene derecho a solicitar la <strong>revisión</strong> del trabajo sin costos adicionales.</li>
          <li>Si el trabajo revisado aún no satisface las especificaciones iniciales, el usuario puede solicitar un <strong>reembolso completo</strong>.</li>
          <li>El plazo para informar de cualquier insatisfacción con el trabajo entregado es de <strong>15 días</strong> desde la fecha de entrega del mismo.</li>
          </ul>

          <h2>3. Anonimato y Confidencialidad</h2>
          <p>Garantizamos el anonimato en todo el proceso de servicios ofrecidos. La confidencialidad de los usuarios y la protección de su identidad son de máxima prioridad para nosotros.</p>

          <h2>4. Soporte al Cliente</h2>
          <p>Nuestro departamento de soporte está disponible <strong>24 horas / 7 días</strong> para resolver cualquier incidencia que pudiera ocurrir. Puede contactarnos en cualquier momento para asistencia relacionada con los servicios que brindamos.</p>

          <h2>5. Uso Aceptable y Limitaciones</h2>
          <p>Los usuarios se comprometen a no utilizar los servicios de TFG Assistant para fines ilícitos o no autorizados. Además, los usuarios deben respetar todos los términos y condiciones aquí estipulados.</p>
          <p>Es importante destacar que el documento final que entrega TFG Assistant <strong>no es apto para presentar directamente ante ningún tribunal académico</strong>. Debe ser el usuario quien revise, ajuste y complete el trabajo en cuestión para su presentación final.</p>

          <h2>6. Propiedad Intelectual</h2>
          <p>El contenido generado por TFG Assistant para los usuarios debe ser usado de acuerdo con las normas de propiedad intelectual y citación académica correspondientes. TFG Assistant retiene los derechos sobre el contenido generado hasta que se completa el pago y la transferencia de los derechos al usuario.</p>

          <h2>7. Modificaciones a los Términos y Condiciones</h2>
          <p>TFG Assistant se reserva el derecho de modificar estos términos y condiciones sin previo aviso. Es responsabilidad del usuario revisar regularmente estos términos para estar al tanto de cualquier cambio.</p>

          <h2>8. Legislación Aplicable</h2>
          <p>Estos términos y condiciones se regirán e interpretarán de acuerdo con las leyes del país de la sede principal de TFG Assistant, sin dar efecto a ningún principio de conflictos de ley.</p>

          <p>Estos términos y condiciones están diseñados para garantizar la eficacia y la responsabilidad en el uso de nuestros servicios. Si tiene alguna pregunta sobre estos términos o necesita más información, no dude en <router-link to="/contactar">contactar</router-link> a nuestro equipo de soporte.</p>

          <p>Gracias por elegir TFG Assistant. Estamos comprometidos a brindarle lo mejor en asistencia académica con los más altos estándares de calidad y integridad.</p>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'App',
    metaInfo: {
      title: 'Términos y Condiciones de Uso de TFG Assistant',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'description', content: 'Conoce los términos y condiciones para el uso de TFG Assistant. Asegura una colaboración eficaz y segura en la elaboración de trabajos finales de grado y máster con nuestra IA.' },
        { name: 'keywords', content: 'términos y condiciones tfg assistant, política de reembolso tfg, anonimato tfg, soporte al cliente tfg assistant, uso tfg assistant, propiedad intelectual tfg, modificaciones términos tfg' },
        // Robots
        { name: 'robots', content: 'index, follow' },
        // Open Graph / Facebook
        //{ property: 'og:title', content: 'El título de tu página' },
        //{ property: 'og:site_name', content: 'El nombre de tu sitio' },
        //{ property: 'og:description', content: 'Descripción de tu página.' },
        //{ property: 'og:type', content: 'website' },
        //{ property: 'og:image', content: 'URL de tu imagen destacada' },
        // Twitter Card
        //{ name: 'twitter:card', content: 'summary_large_image' },
        //{ name: 'twitter:title', content: 'El título de tu página' },
        //{ name: 'twitter:description', content: 'Descripción de tu página.' },
        //{ name: 'twitter:image', content: 'URL de tu imagen destacada' },
      ]
    },
  };
  </script>