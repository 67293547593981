<template>
  <v-container>
    <v-form ref="form" @submit.prevent="sendEmail" aria-label="Formulario de contacto para dudas y preguntas">
      <v-text-field
        v-model="form.name"
        label="Nombre"
        required
        aria-required="true"
        placeholder="Introduce tu nombre"
      ></v-text-field>
      <v-text-field
        v-model="form.email"
        label="Correo Electrónico"
        required
        aria-required="true"
        placeholder="Introduce tu correo electrónico"
      ></v-text-field>
      <v-text-field
        v-model="form.subject"
        label="Asunto de tu mensaje"
        required
        aria-required="true"
        placeholder="Escribe el asunto"
      ></v-text-field>
      <v-textarea
        v-model="form.message"
        label="Descripción de la duda o incidente"
        required
        aria-required="true"
        placeholder="Detalla aquí tu consulta o reporte"
      ></v-textarea>
      <v-btn type="submit" color="primary" aria-label="Enviar mensaje">Enviar</v-btn>
      <v-alert 
        v-if="alert.visible" 
        :type="alert.type" 
        dismissible 
        @input="alert.visible = false">
        {{ alert.message }}
      </v-alert>
    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      form: {
        name: '',
        email: '',
        subject: '',
        message: ''
      },
      alert: {
        visible: false,
        message: '',
        type: ''
      }
    };
  },
  methods: {
    async sendEmail() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const fechaActual = new Date().toISOString().split('T')[0];
      const formData = new FormData();
      formData.append('nombre', this.form.name);
      formData.append('email', this.form.email);
      formData.append('asunto', this.form.subject);
      formData.append('mensaje', this.form.message);
      formData.append('creado', fechaActual);

      try {
        const response = await axios.post('/api/addSoporte.php', formData);
        console.log('raw response', response);
        const data = response.data;
        
        console.log('parsed response', data);
        if (data.success) {
          this.resetForm();
          this.showAlert('Tu mensaje ha sido enviado exitosamente. ¡Gracias por contactarnos!', 'success');
        } else {
          this.showAlert('Error en el servidor: ' + data.message, 'error');
        }
      } catch (error) {
        console.log('catch error', error);
        this.showAlert('Error enviando el formulario: ' + error.message, 'error');
      }
    },
    resetForm() {
      this.form = {
        name: '',
        email: '',
        subject: '',
        message: ''
      };
    },
    showAlert(message, type) {
      this.alert = {
        visible: true,
        message: message,
        type: type
      };
    }
  }
};
</script>