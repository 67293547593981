<template>
    <v-container>
      <v-row justify="center">
          <v-col cols="12" md="10" lg="8">
          


            <h1>Índice de Preguntas Frecuentes (FAQ)</h1>
            
            <h2>General</h2>
            
            <h2>1. ¿Qué es TFG Assistant?</h2>
            <p>TFG Assistant es una innovadora plataforma de redacción académica diseñada para ayudar a estudiantes y profesionales en la elaboración de Trabajos de Fin de Grado (TFG) y Trabajos de Fin de Máster (TFM). Utilizando avanzada tecnología de inteligencia artificial basada en el modelo GPT-4o de OpenAI, TFG Assistant genera borradores de documentos que son coherentes, bien estructurados y personalizados según las especificaciones del usuario. Su objetivo principal es facilitar el proceso de redacción académica, asegurando documentos de alta calidad que cumplen con los estándares académicos requeridos.</p>
            
            <h2>2. ¿Cómo funciona TFG Assistant?</h2>
            <p>TFG Assistant funciona a través de un proceso sencillo y guiado:</p>
            <ul>
            <li><strong>Inicio de Proyecto</strong>: Los usuarios comienzan introduciendo detalles específicos de sus necesidades académicas a través de un formulario interactivo. Esta información incluye el título del trabajo, área de estudio, estilo de citación preferido, y cualquier otro requisito específico.</li>
            <li><strong>Generación de Documento</strong>: Utilizando la información provista, TFG Assistant emplea algoritmos de IA para estructurar y redactar el documento. Dicho proceso incluye la creación de una estructura coherente del documento, desarrollo de contenido y la integración correcta de las citas y referencias.</li>
            <li><strong>Revisión y Entrega</strong>: Una vez generado el documento, se ofrece al usuario la oportunidad de revisarlo y solicitar ajustes específicos si es necesario. Finalmente, el documento es entregado en formato Word o PDF, listo para ser utilizado como un borrador avanzado que el usuario puede finalizar y ajustar según necesite.</li>
            </ul>
            
            <h2>3. ¿Quiénes pueden beneficiarse de TFG Assistant?</h2>
            <p>TFG Assistant está diseñado para beneficiar a una amplia gama de usuarios dentro del ámbito académico, incluyendo:</p>
            <ul>
            <li><strong>Estudiantes Universitarios</strong>: Especialmente aquellos que están en las etapas de redacción de sus trabajos de fin de grado o máster. TFG Assistant les ayuda a superar bloqueos de escritor, asegura la coherencia en su documentación y les ahorra tiempo significativo.</li>
            <li><strong>Profesores y Tutores Académicos</strong>: Que pueden utilizar la plataforma para ayudar a sus alumnos en el proceso de redacción y estructuración de sus investigaciones.</li>
            <li><strong>Redactores Académicos Profesionales</strong>: Quienes se benefician del uso de TFG Assistant para optimizar procesos y aumentar la productividad al manejar múltiples proyectos.</li>
            </ul>
            
            <h2>Sobre el Servicio</h2>
            
            <h2>4. ¿Qué tipo de documentos puede redactar TFG Assistant?</h2>
            <p>TFG Assistant está equipado para ayudar en la redacción de una amplia gama de documentos académicos, incluyendo Trabajos de Fin de Grado (TFG), Trabajos de Fin de Máster (TFM), ensayos, informes de investigación, y artículos académicos. El sistema está diseñado para adaptarse a variados campos de estudio, desde las ciencias sociales y humanidades hasta las ciencias exactas y la ingeniería, ofreciendo estructuras y contenidos específicos que cumplen con los requisitos académicos de distintas disciplinas.</p>
                        
            <h2>5. ¿En qué formatos se entregan los documentos?</h2>
            <p>Los documentos generados por TFG Assistant se entregan en formatos fácilmente editables y universales, principalmente en Microsoft Word (docx). Esto permite a los usuarios realizar ajustes adicionales, personalizar el formato según sus necesidades específicas o las directrices de sus instituciones educativas, y tener un control total sobre el documento final antes de su presentación oficial.</p>
            
            <h2>6. ¿Puedo personalizar el estilo y formato de mi documento?</h2>
            <p>Sí, TFG Assistant ofrece opciones de personalización extensa. A través de la plataforma, puedes especificar el estilo de cita (APA, MLA, Chicago, entre otros), estructura deseada del documento, y cualquier requerimiento específico relacionado con el formato, como el tamaño de fuente, espaciado entre líneas, y márgenes de página. Nuestro objetivo es asegurarnos de que el documento final cumpla no solo con tus expectativas de contenido, sino también con todas las normativas formales exigidas por tu institución académica.</p>
            
            <h2>7. ¿TFG Assistant garantiza la originalidad de los documentos?</h2>
            <p>Absolutamente. TFG Assistant genera todos los documentos desde cero utilizando tecnología de generación de texto avanzado de OpenAI, empleando GPT-4oo que garantiza la originalidad del contenido. Además, todos los documentos pasan por un control riguroso de plagio utilizando software de detección líder en el mercado, para asegurar que el texto final sea único. Proporcionamos un informe de plagio con cada documento para que los usuarios puedan estar seguros de la autenticidad y singularidad del contenido entregado. Asimismo, la tecnología de IA que utilizamos está diseñada para producir textos que no son identificables por los detectores anti-IA, garantizando que el trabajo sea percibido como escrito directamente por un humano.</p>
            
            <h2>Uso del Servicio</h2>
            
            <h2>8. ¿Cómo inicio un proyecto con TFG Assistant?</h2>
            <p>Iniciar un proyecto con TFG Assistant es un proceso sencillo y directo. Primero, deberás visitar nuestro sitio web y hacer clic en el botón de <strong><a href="/formulario-compra">COMPRAR</a></strong>. Se te guiará a través de un formulario interactivo donde proporcionarás detalles específicos sobre tu trabajo, incluyendo el tema, las especificaciones de formato, el número de páginas esperado, y cualquier instrucción especial relacionada con el contenido o la estructura. Una vez que hayas proporcionado toda la información requerida y realizado el pago, tu proyecto comenzará a ser procesado por nuestro sistema de IA, que generará un borrador de acuerdo a tus especificaciones.</p>
            
            <h2>9. ¿Qué información necesito proporcionar para comenzar?</h2>
            <p>Para garantizar que TFG Assistant pueda generar el mejor contenido posible, necesitarás proporcionar información detallada sobre tu proyecto. Esto incluye:</p>
            <ul>
            <li>El título de tu trabajo o una descripción clara del tema.</li>
            <li>El número de páginas requerido.</li>
            <li>El estilo de citación preferido (APA, MLA, Chicago, etc.).</li>
            <li>Cualquier estructura específica del documento o subdivisiones requeridas.</li>
            <li>Fuentes preferidas o necesarias, si las hay.</li>
            <li>Fecha límite para la entrega del proyecto.</li>
            <li>Cualquier otra instrucción o requerimiento específico que pueda influir en la redacción del documento.</li>
            </ul>
            
            <h2>10. ¿Puedo pedir una revisión o modificación una vez que mi documento está completado?</h2>
            <p>Sí, en TFG Assistant, entendemos que puede haber necesidad de ajustes una vez que recibes tu documento. Ofrecemos la posibilidad de solicitar revisiones o modificaciones después de la entrega del borrador inicial. Los usuarios pueden enviar detalles específicos sobre los cambios o mejoras que desean, y nuestro sistema realizará las modificaciones correspondientes. Cada pedido incluye una <strong> revisión gratuita dentro de los 14 días posteriores a la entrega </strong> del documento, asegurando que el trabajo final cumpla completamente con tus expectativas.</p>
            <h2>11. ¿Cómo maneja TFG Assistant las citas y las referencias bibliográficas?</h2>
            <p>TFG Assistant utiliza tecnología avanzada para manejar adecuadamente las citas y las referencias bibliográficas de acuerdo con los estándares académicos. La plataforma permite a los usuarios especificar el estilo de citación deseado, y el sistema automáticamente formatea todas las referencias según la guía correspondiente, asegurando la consistencia y la precisión. Además, TFG Assistant puede integrar fuentes que los usuarios proporcionen específicamente o utilizar bases de datos de acceso abierto para enriquecer el contenido del documento y apoyar argumentos y afirmaciones, garantizando así que todas las citas sean relevantes y verificables.</p>
            
            <h2>Precios y Pagos</h2>
            
            <h2>12. ¿Cómo se determinan los precios en TFG Assistant?</h2>
            <p>Los precios en TFG Assistant se determinan en base a varios factores para asegurar que cada cliente reciba un servicio justo y transparente. Estos factores incluyen:</p>
            <ul>
            <li><strong>Número de páginas</strong>: Cuántas páginas se requieren para el documento.</li>
            <li><strong>Urgencia</strong>: Dependiendo de si el pedido es estándar o urgente.</li>
            <li><strong>Complejidad del tema</strong>: Algunos temas pueden requerir investigación más detallada o acceso a fuentes especializadas.</li>
            
            <p>Estos componentes se combinan para formar una estructura de precios que es clara desde el inicio, sin costos ocultos, asegurando que los estudiantes y profesionales puedan planificar sus gastos de manera efectiva.</p>
            </ul>
            
            <h2>13. ¿Qué métodos de pago acepta TFG Assistant?</h2>
            <p>TFG Assistant acepta una variedad de métodos de pago para facilitar a los usuarios la contratación de nuestros servicios. Esto incluye:</p>
            <ul>
            <li><strong>Tarjetas de crédito y débito</strong>: Las más comunes como Visa, MasterCard y American Express.</li>
            <li><strong>PayPal</strong>: Para usuarios que prefieren un método de pago seguro y que no requiera compartir detalles financieros directamente.</li>
            <li><strong>Transferencias bancarias</strong>: Para aquellos que optan por un método de pago más tradicional.
            Todos los pagos se procesan a través de plataformas seguras para garantizar la protección de tus datos financieros.</li>
            </ul>
            
            <h2>14. ¿Es seguro hacer un pago en su sitio web?</h2>
            <p>Sí, la seguridad de tus datos es una prioridad para nosotros en TFG Assistant. Utilizamos tecnología de cifrado de datos líder en la industria y trabajamos solo con plataformas de pago establecidas y seguras para procesar todas las transacciones. Además, no almacenamos información de tarjetas de crédito o cuentas bancarias en nuestros servidores para maximizar tu seguridad.</p>
            
            <h2>15. ¿Hay algún costo oculto o adicional que deba tener en cuenta?</h2>
            <p>No, en TFG Assistant nos comprometemos con la transparencia completa en nuestra estructura de precios. Todos los costos se comunican claramente en el momento de la reserva del servicio. No hay tarifas ocultas ni cargos adicionales que no se hayan especificado previamente. La tarifa que ves al momento de completar tu pedido incluye todas las características del servicio listadas, y cualquier servicio adicional fuera del paquete elegido será discutido y acordado antes de su implementación.</p>
            <h2>Problemas Técnicos</h2>
            
            <h2>16. ¿Qué hago si tengo problemas para acceder a mi cuenta?</h2>
            <p>Si encuentras dificultades para acceder a tu cuenta de TFG Assistant, te recomendamos seguir estos pasos:</p>
            <ul>
            <li><strong>Verifica tu Conexión a Internet</strong>: Asegúrate de que tu dispositivo esté correctamente conectado a internet.</li>
            <li><strong>Recuperación de Contraseña</strong>: Utiliza la opción de &quot;Olvidé mi contraseña&quot; en la página de inicio de sesión para restablecer tu contraseña mediante un enlace que será enviado a tu correo electrónico registrado.</li>
            <li><strong>Limpiar Caché y Cookies</strong>: A veces, limpiar la caché y las cookies de tu navegador puede resolver problemas de acceso.</li>
            <li><strong>Soporte Técnico</strong>: Si los pasos anteriores no resuelven el problema, puedes contactar a nuestro soporte técnico enviando un correo electrónico a nuestro centro de ayuda o llamando al número de soporte proporcionado en nuestra página web. Estamos disponibles para ayudarte a resolver cualquier problema técnico que puedas tener.</li>
            </ul>
            
            <h2>17. ¿Cómo puedo recuperar mi documento si lo pierdo?</h2>
            <p>Si por alguna razón pierdes el documento que TFG Assistant ha generado para ti, tenemos varias medidas de seguridad para ayudarte a recuperarlo:</p>
            <ul>
            <li><strong>Backup Automático</strong>: Todos los documentos generados se guardan automáticamente en tu cuenta de TFG Assistant durante un periodo específico.</li>
            <li><strong>Correo Electrónico</strong>: Cuando completamos un documento, también enviamos una copia a tu dirección de correo electrónico registrada como medida de respaldo.</li>
            <li><strong>Solicitud de Recuperación</strong>: Si no puedes encontrar el documento en tu correo o en tu cuenta, puedes solicitar una copia al servicio de atención al cliente, quien estará listo para asistirte y asegurar que recuperes tu trabajo.</li>
            </ul>
            
            <h2>18. ¿Qué sucede si no estoy satisfecho con el documento entregado?</h2>
            <p>En TFG Assistant, nos esforzamos por garantizar la satisfacción completa de todos nuestros clientes. Si el documento entregado no cumple con tus expectativas o con los estándares especificados, ofrecemos las siguientes opciones:</p>
            <ul>
            <li><strong>Revisiones Gratuitas</strong>: Puedes solicitar revisiones del documento sin cargo adicional. Haznos saber qué áreas necesitan mejoras, y nuestro sistema de IA realizará los ajustes necesarios.</li>
            <li><strong>Soporte Directo</strong>: Si las revisiones no solucionan las preocupaciones, nuestro equipo de soporte está disponible para trabajar directamente contigo y encontrar una solución adecuada.</li>
            <li><strong>Política de Reembolso</strong>: En casos donde las soluciones anteriores no sean suficientes, se evaluará una posible política de reembolso basada en los términos de servicio acordados y la naturaleza del problema presentado.</li>
            </ul>
            <p>Estamos comprometidos a ofrecer altos estándares de calidad y atención al cliente, y trabajaremos contigo para asegurarnos que el resultado final cumpla completamente tus necesidades académicas.</p>
            
            <h2>Legal y Privacidad</h2>

            <h2>19. ¿Cómo asegura TFG Assistant la confidencialidad de mi información?</h2>
    
            <p>En TFG Assistant, la confidencialidad y la seguridad de la información de nuestros usuarios son de máxima prioridad. Implementamos varias medidas para proteger tus datos:</p>
            <ul>
            <li><strong>Cifrado de Datos:</strong> Utilizamos tecnologías de cifrado avanzadas para asegurar que toda la información transmitida y almacenada en nuestra plataforma sea completamente segura.</li>
            <li><strong>Control de Acceso:</strong> Limitamos el acceso a la información personal y a los documentos de los usuarios únicamente a aquellos empleados que necesitan acceder a ella para realizar su trabajo.</li>
            <li><strong>Protocolos de Seguridad Estrictos:</strong> Seguimos protocolos rigurosos de seguridad en todas nuestras operaciones y nuestras bases de datos están protegidas contra accesos no autorizados.</li>
            <li><strong>Cumplimiento de Normativas de Privacidad:</strong> Nos aseguramos de cumplir con todas las leyes y regulaciones de privacidad aplicables para proteger la información de los usuarios.</li>
            </ul>

            <h2>20. ¿TFG Assistant utiliza mi documento para cualquier otro propósito?</h2>
     
            <p>No, TFG Assistant no utiliza los documentos ni la información creada por los usuarios para ningún otro propósito que no sea la generación del documento solicitado. Los documentos generados son propiedad exclusiva del usuario, y mantenemos una política estricta de no reutilización, publicación o divulgación de dichos documentos. Todo el contenido creado es único para el usuario y confidencial.</p>
      
            <h2>21. ¿Qué políticas de privacidad y seguridad implementa TFG Assistant?</h2>
  
            <p>TFG Assistant implementa varias políticas de privacidad y seguridad para proteger la integridad y confidencialidad de la información de nuestros usuarios:</p>
            <ul>
            <li><strong>Política de Privacidad:</strong> Nuestra política de privacidad detalla cómo recopilamos, usamos, protegemos y almacenamos tu información personal y los documentos que generas.</li>
            <li><strong>Protección de Datos:</strong> Siguiendo las mejores prácticas y cumpliendo con regulaciones como el GDPR para usuarios en la Unión Europea, implementamos medidas de seguridad robustas para proteger tus datos.</li>
            <li><strong>Revisión Continua:</strong> Revisamos y actualizamos continuamente nuestras políticas y prácticas de seguridad para asegurar que se mantengan al día con las normativas legales y tecnológicas.</li>
            <li><strong>Transparencia:</strong> Nos comprometemos a ser transparentes sobre nuestras prácticas de seguridad y privacidad y a informar a los usuarios sobre cualquier cambio significativo en la manera en que tratamos su información personal.</li>
            </ul>
            
            <h2>Apoyo y Contacto</h2>

            <h2>22. ¿Cómo puedo contactar al servicio de atención al cliente?</h2>

            <p>Puedes contactar a nuestro servicio de atención al cliente de varias maneras para asegurarte que recibas la ayuda que necesitas:</p>
            <ul>
            <li><strong>Correo Electrónico:</strong> Envía tus preguntas o preocupaciones a nuestro correo de soporte, que se encuentra disponible en nuestra página web.</li>
            <li><strong>Teléfono:</strong> Tenemos una línea telefónica dedicada para soporte al cliente. Puedes llamar durante los horarios de oficina que están claramente indicados en nuestra página de contacto.</li>
            <li><strong>Formulario de Contacto:</strong> También puedes llenar un formulario en nuestra página de contacto con tus dudas o comentarios, y nos aseguraremos de responder lo más pronto posible.</li>
            </ul>

            <h2>23. ¿En qué horarios está disponible el soporte al cliente?</h2>
   
            <p>Nuestro soporte al cliente está disponible de lunes a viernes de 9:00 a 18:00 horas (horario peninsular). Nos esforzamos por responder todas las consultas lo más rápidamente posible y proporcionar asistencia continua para garantizar que tus necesidades sean atendidas de manera eficiente.</p>
    
            <h2>24. ¿TFG Assistant ofrece algún servicio de asesoramiento post-venta?</h2>
       
            <p>Sí, en TFG Assistant valoramos la experiencia completa del cliente y ofrecemos servicios de asesoramiento post-venta para asegurar que estés completamente satisfecho con el documento final. Esto incluye:</p>
            <ul>
            <li><strong>Asistencia con Revisiones:</strong> Después de la entrega de tu documento, puedes solicitar asistencia para comprender y aplicar las revisiones necesarias para cumplir con tus requisitos específicos.</li>
            <li><strong>Soporte Técnico Continuo:</strong> Si experimentas problemas técnicos o necesitas ayuda con la funcionalidad del documento, nuestro equipo está a tu disposición para proporcionar soporte.</li>
            <li><strong>Seguimiento de la Satisfacción:</strong> Realizamos un seguimiento de la satisfacción de todos nuestros clientes para asegurarnos de que nuestros servicios han cumplido con sus expectativas y aprender cómo podemos mejorar en el futuro.</li>
            </ul>
            
            <h2>Tecnología y Capacidades de IA</h2>

            <h2>25. ¿Qué tecnología de IA utiliza TFG Assistant?</h2>

            <p>TFG Assistant utiliza el modelo GPT-4o desarrollado por OpenAI, que es uno de los sistemas de inteligencia artificial más avanzados en procesamiento de lenguaje natural (NLP). Esta tecnología permite a TFG Assistant entender y generar texto humano con un alto nivel de coherencia, complejidad y relevancia. GPT-4o es capaz de realizar tareas de redacción, resumen, traducción y generación de contenido creativo, basándose en las instrucciones y datos proporcionados por los usuarios, lo que garantiza documentos de alta calidad, personalizados según las necesidades específicas de cada proyecto.</p>
  
            <h2>26. ¿Cómo asegura TFG Assistant que los textos generados no serán detectados como escritos por IA?</h2>
   
            <p>TFG Assistant implementa varias estrategias para garantizar que los documentos generados parezcan tan naturales y humanos como sea posible:</p>
            <ul>
            <li><strong>Diversificación del Lenguaje:</strong> Utilizamos sofisticadas técnicas de IA para variar el estilo de escritura y evitar patrones repetitivos que podrían ser detectados por software anti-IA.</li>
            <li><strong>Revisiones Semánticas:</strong> Los documentos pasan por controles de coherencia y relevancia para asegurar que el contenido no solo es único sino ajustado al contexto y objetivo académico deseado.</li>
            <li><strong>Actualizaciones Constantes de Modelos:</strong> Mantenemos nuestros modelos de IA actualizados con las últimas investigaciones y avances en el campo de la inteligencia artificial, asegurándonos de que las técnicas que empleamos están siempre a la vanguardia en términos de indetectabilidad y eficacia.</li>
            </ul>
  
            <h2>27. ¿Es posible que TFG Assistant se equivoque o genere información inexacta?</h2>

            <p>Si bien la tecnología de IA que utilizamos es altamente avanzada y diseñada para ofrecer precisión y confiabilidad, como con cualquier herramienta, existe la posibilidad de errores o imprecisiones. Para minimizar estos riesgos, TFG Assistant incorpora varias medidas de control de calidad:</p>
            <ul>
            <li><strong>Verificación de Fuentes:</strong> Todos los datos y contenidos generados se basan en fuentes verificadas y fiables.</li>
            <li><strong>Revisión Humana:</strong> Recomendamos que todas las producciones de TFG Assistant sean revisadas por el usuario o por un experto humano, especialmente para asegurar la precisión técnica y académica en temas altamente especializados.</li>
            <li><strong>Retroalimentación Continua:</strong> Permitimos e incentivamos la retroalimentación de los usuarios para mejorar continuamente la precisión y eficacia de nuestro servicio.</li>
            </ul>
            
            <h2>Uso de Datos</h2>

            <h2>28 ¿Cómo maneja TFG Assistant los datos proporcionados por los usuarios?</h2>
            <p>En TFG Assistant, tratamos la seguridad y la privacidad de los datos con la máxima seriedad. Los datos que los usuarios proporcionan se utilizan exclusivamente para la generación de los documentos solicitados y para mejorar la experiencia del usuario. Aquí están las medidas específicas que implementamos:</p>

            <ul>
            <li><strong>Almacenamiento Seguro:</strong> Todos los datos se almacenan en servidores seguros y protegidos con cifrado de última generación. Solo personal autorizado tiene acceso a estos datos y solo para fines de soporte y mejora del servicio.</li>
            <li><strong>Limitación del Uso de Datos:</strong> Los datos que recibimos son utilizados exclusivamente por el sistema de TFG Assistant para generar y mejorar los documentos solicitados. No usamos esta información para ningún otro propósito ni la compartimos con terceros.</li>
            <li><strong>Periodo de Retención:</strong> Los datos se almacenan durante el tiempo necesario para cumplir con el propósito para el cual fueron recopilados, después de lo cual se eliminan de forma segura.</li>
            </ul>
            
            <h2>29. ¿TFG Assistant vende o comparte mis datos con terceros?</h2>

            <p>No, TFG Assistant no vende ni comparte los datos de los usuarios con terceros. Mantenemos un compromiso firme con la protección de la privacidad de los usuarios. La confianza de nuestros usuarios es fundamental para nuestro servicio, por lo tanto:</p>
            <ul>
            <li><strong>No a la Venta de Datos:</strong> Los datos personales y del proyecto que nos proporcionas no se venden bajo ninguna circunstancia a empresas de marketing, investigadores de datos, o cualquier otra entidad.</li>
            <li><strong>Restrictiva Compartición de Datos:</strong> Solo compartimos datos con terceros cuando es estrictamente necesario para la operación del servicio, como procesadores de pagos para transacciones financieras. En estos casos, los terceros están también comprometidos con la protección de datos y actúan bajo nuestros estrictos protocolos de privacidad.</li>
            <li><strong>Transparencia en la Política de Datos:</strong> Siempre informamos a los usuarios claramente cómo y con quién se comparten sus datos, asegurando cumplimiento con leyes de privacidad y dando a los usuarios el control sobre su información.</li>
            </ul>
            
            <h2>Desempeño y Mejora Continua</h2>
 
            <h2>30. ¿TFG Assistant se actualiza para mejorar su rendimiento y precisión?</h2>
   
            <p>Sí, en TFG Assistant nos comprometemos a la mejora continua del servicio que ofrecemos. Nuestro equipo técnico trabaja constantemente en la actualización y optimización de nuestro software, incluyendo el modelo GPT-4o de OpenAI que utilizamos para generar los documentos. Estas actualizaciones incluyen:</p>
            <ul>
            <li><strong>Mejoras en IA:</strong> Regularmente integramos las últimas innovaciones y avances en inteligencia artificial para mejorar la calidad, precisión y naturalidad de los textos generados.</li>
            <li><strong>Actualización de Bases de Datos:</strong> Actualizamos y ampliamos nuestras bases de datos de investigación y fuentes para asegurar que la información utilizada en la generación de contenido sea la más actual y relevante.</li>
            <li><strong>Mejoras Basadas en Feedback:</strong> Tomamos en cuenta las retroalimentaciones y sugerencias de nuestros usuarios para hacer ajustes que mejoren la experiencia del usuario y la calidad del producto final.</li>
            </ul>
    
            <h2>31. ¿Cómo maneja TFG Assistant los feedbacks y sugerencias de los usuarios?</h2>

            <p>En TFG Assistant valoramos enormemente los comentarios y sugerencias de nuestros usuarios, ya que juegan un papel crucial en nuestro proceso de mejora continua. He aquí cómo gestionamos y utilizamos los feedbacks:</p>
            <ul>
            <li><strong>Canal Abierto de Comunicación:</strong> Facilitamos fácil acceso a canales donde los usuarios pueden dejar sus comentarios, ya sea a través de nuestro sitio web, por correo electrónico, o mediante encuestas de satisfacción.</li>
            <li><strong>Análisis de Feedbacks:</strong> Todos los comentarios recibidos son analizados por nuestro equipo para comprender las necesidades, experiencias y problemas de los usuarios. Esto nos ayuda a identificar áreas de mejora.</li>
            <li><strong>Implementación de Mejoras:</strong> Implementamos mejoras basadas en los feedbacks que recibimos, lo cual puede incluir desde ajustes en la interfaz de usuario, hasta mejoras técnicas en la generación de contenido.</li>
            <li><strong>Comunicación de Cambios:</strong> Nos comunicamos activamente con nuestros usuarios acerca de cómo sus sugerencias han sido implementadas, cerrando el ciclo de feedback y reforzando nuestro compromiso con la atención al cliente y la excelencia en el servicio.</li>
            </ul>
            
          </v-col>
        </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'App',

    data() {
        return {
        headers: []
        };
    },

    mounted() {
        this.updateHeaders();
    },

    methods: {
        updateHeaders() {
        // Obtener todos los h2 del contenedor principal
        const headers = document.querySelectorAll('h2');
        this.headers = Array.from(headers).map(header => {
            const id = header.getAttribute('id') || header.innerText.replace(/\s+/g, '-').toLowerCase();
            header.setAttribute('id', id);
            return {
            text: header.innerText,
            id: id
            };
        });
        }
    },

    metaInfo: {
        title: 'FAQ - Preguntas Frecuentes sobre TFG / TFM | TFG Assistant',
        meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'description', content: 'Conoce las respuestas a las preguntas más frecuentes sobre la elaboración de TFG y TFM con TFG Assistant.' },
        { name: 'keywords', content: 'FAQ, Preguntas Frecuentes, TFG Assistant, TFG, TFM, elaboración de TFG, elaboración de TFM' },
        { name: 'robots', content: 'index, follow' }
        ]
    }
    };
  </script>

<style scoped>
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100vh;
  overflow-y: auto;
  background-color: #f8f9fa;
  padding: 20px;
  border-right: 1px solid #ddd;
}

.sidebar a {
  text-decoration: none;
  color: #007bff;
  transition: color 0.3s;
}

.sidebar a:hover {
  color: #0056b3;
}

.content {
  margin-left: 220px; /* Para compensar el ancho de la barra lateral */
}

html {
  scroll-behavior: smooth;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .content {
    margin-left: 0;
  }
}

</style>