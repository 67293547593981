<template>
  <v-app dark>
    <!-- Menú Lateral -->
    <v-navigation-drawer
      v-model="drawer"
      :permanent="$vuetify.breakpoint.mdAndUp"
      :temporary="!$vuetify.breakpoint.mdAndUp"
      fixed
      app
      dark
      color="primary-darken-4"
      class="sidebar"
    >
      <v-list dense class="pt-0">
        <v-subheader>Menú</v-subheader>
        <v-divider></v-divider>
        <div v-for="(group, index) in groupedNavigationLinks" :key="index">
          <v-list-item-group>
            <v-list-item
              v-for="link in group"
              :key="link.title"
              :to="link.to"
              link
              active-class="active-list-item"
              exact
              @click="drawer = false"
            >
              <v-list-item-icon>
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ link.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <v-layout
            v-if="index < groupedNavigationLinks.length - 1"
            align-center
            class="mb-2"
          >
            <v-divider></v-divider>
          </v-layout>
        </div>
      </v-list>
    </v-navigation-drawer>

    <!-- Barra de Aplicación -->
    <v-app-bar :elevation="2" app dark color="primary-darken-4">
      <template v-slot:prepend>
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          v-if="$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smAndDown"
        ></v-app-bar-nav-icon>
      </template>

      <!-- Título en la Barra de Aplicación con logotipo -->
      <router-link to="/" class="d-flex align-center logo-link">
        <v-img
          src="@/assets/tfg_assistant_logo.jpg"
          alt="Logotipo TFG Assistant"
          contain
          max-width="40px"
          max-height="40px"
          class="mr-3"
        ></v-img>
        <span class="d-none d-md-inline">TFG ASSISTANT</span>
      </router-link>

      <v-spacer></v-spacer>

      <!-- Botón de Oferta de Lanzamiento -->
      <v-btn color="warning" dark class="ml-4" to="/formulario-compra">
        COMPRAR
      </v-btn>

      <!-- Menú de Puntos -->
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" color="white">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="link in navigationLinks"
            :key="link.title"
            :to="link.to"
            link
            active-class="active-list-item"
            exact
          >
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ link.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- Contenido Principal -->
    <v-main>
      <v-container class="main-content">
        <v-alert type="error" class="mb-5 promo-text text-center" text>
          !OFERTA DE LANZAMIENTO! 60% DE DESCUENTO EN TODOS NUESTROS SERVICIOS
        </v-alert>

        <v-divider inset class="my-5" color="deep-purple accent-4" thickness="5"></v-divider>

        <router-view />

        <cookie-consent />
      </v-container>
    </v-main>

    <!-- Pie de página o navegación inferior -->
    <v-footer
      v-if="!$vuetify.breakpoint.xsOnly"
      color="blue-grey darken-2"
      dark
      app
      height="auto"
    >
      <v-container>
        <v-row justify="center" align="center" no-gutters class="text-center">
          <v-col cols="12" md="4">
            <v-icon small class="mr-2">mdi-email</v-icon>
            <a href="/contactar" class="white--text text-decoration-none">
              contactar@tfgassistant.com
            </a>
          </v-col>
          <v-col cols="12" md="4">
            <v-icon small class="mr-2">mdi-whatsapp</v-icon>
            <span>+34 644 717 336</span>
          </v-col>
          <v-col cols="12" md="4">
            <span>&copy; 2024 TFG Assistant. Todos los derechos reservados.</span>
          </v-col>
        </v-row>
      </v-container>      
    </v-footer>

    <v-bottom-navigation
      v-else
      fixed
      grow
      color="primary-darken-3"
      dark
      app
    >
      <v-btn text link to="/Ejemplos">
        <span>Ejemplos</span>
        <v-icon>mdi-file-table-box-multiple-outline</v-icon>
      </v-btn>
      <v-btn text link to="/como-funciona">
        <span>Guía de Uso</span>
        <v-icon>mdi-cogs</v-icon>
      </v-btn>
      <v-btn text link to="/contactar">
        <span>Contacto</span>
        <v-icon>mdi-email</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
import CookieConsent from "./components/CookieConsent.vue";

// Agrupación de los enlaces de navegación para mejorar la organización
const navigationLinks = [
  { to: "/", title: "Inicio", icon: "mdi-home" },
  { to: "/serviciosOfrecidos", title: "Nuestros Servicios", icon: "mdi-cog-outline" },
  { to: "/lista-de-precios", title: "Precios", icon: "mdi-currency-eur" },
  { to: "/como-funciona", title: "Guía de Uso", icon: "mdi-cogs" },
  { to: "/formulario-compra", title: "Comprar TFG / TFM AI", icon: "mdi-cart" },
  { to: "/Ejemplos", title: "Ejemplos", icon: "mdi-file-table-box-multiple-outline" },
  { to: "/Soporte", title: "Soporte", icon: "mdi-lifebuoy" },
  { to: "/faq", title: "Preguntas Frecuentes", icon: "mdi-help-circle-outline" },
  { to: "/contactar", title: "Contacta con nosotros", icon: "mdi-email-outline" },
  { to: "/sobre-nosotros", title: "Sobre Nosotros", icon: "mdi-information" },
  { to: "/condiciones", title: "Términos y Condiciones de uso", icon: "mdi-gavel" },
  { to: "/privacidad", title: "Política de Privacidad", icon: "mdi-shield-account" },
];

export default {
  components: {
    CookieConsent,
  },
  name: "App",
  data: () => ({
    drawer: null,
    navigationLinks,
    groupedNavigationLinks: [navigationLinks.slice(0, 6), navigationLinks.slice(6)],
    metaInfo: {
      title: "TFG ASSISTANT",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          name: "description",
          content:
            "Servicio de redacción personalizada de TFG/TFM con GPT-4o de OpenAI. Garantizamos trabajos completos, entregados en 24 horas, libres de plagio y detectores Anti-AI.",
        },
        {
          name: "keywords",
          content:
            "Servicio redacción TFG, asistencia TFG / TFM, elaboración proyecto grado, ayuda personalizada TFG / TFM, documento DOCX TFG / TFM, proyecto final universidad asistencia, soporte integral redacción académica, contenido original TFG, calidad asegurada TFG / TFM, entrega rápida proyecto grado, asesoría TFG / TFM personalizada, redacción sin plagio TFG.",
        },
        { name: "robots", content: "index, follow" },
      ],
    },
  }),
};
</script>

<style scoped>
.main-content {
  padding: 20px !important;
}

.text-center {
  text-align: center;
}

.no-style-link {
  color: inherit;
  text-decoration: none;
}

.no-style-link:hover {
  text-decoration: underline;
}

.footer-container {
  max-width: 100%;
  padding: 20px 0;
}

.transparent {
  background-color: transparent;
}

.text-decoration-none {
  text-decoration: none;
}

.white--text {
  color: white !important;
}

.sidebar {
  background-color: #1e1e1e;
}

.v-list-item,
.v-list-item-icon {
  color: white;
}

.logo-link {
  text-decoration: none;
  font-family: "Roboto Mono", monospace;
  font-size: 1.5rem;
  color: white;
  transition: color 0.3s;
}

.logo-link:hover {
  color: #82b1ff;
}

/* Estilos para el texto de promoción */
.promo-text {
  color: white !important;   /* Cambia el color del texto a blanco */
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

/* Estilos para los ítems activos del menú */
.active-list-item {
  background-color: rgba(255, 255, 255, 0.1);
  color: #82b1ff !important; /* Cambia el color del texto del ítem activo */
}

/* Estilos para el menú de puntos */
.v-menu .v-list-item.active-list-item {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #82b1ff !important; /* Cambia el color del texto para ítems activos del menú de puntos */
}

/* Específicos del componente de cookie */
.v-snackbar button {
  color: white !important;
}
</style>