<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>

      <!-- Personal Information Section -->
      <v-card elevation="2" class="mb-4">
        <v-card-title>Información Personal</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field label="Nombre" v-model="selectedNombre" :rules="[v => !!v || 'Este campo es requerido']"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field label="Email" v-model="selectedMail" :rules="[v => !!v || 'Este campo es requerido', v => /.+@.+\..+/.test(v) || 'El email debe ser válido']"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Academic Information Section -->
      <v-card elevation="2" class="mb-4">
        <v-card-title>Información Académica</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-select :items="carreras" label="Carrera" v-model="selectedCarrera" :rules="[v => !!v || 'Este campo es requerido']"></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field label="Especialidad" v-model="selectedEspecialidad" placeholder="Escribe la especialidad"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-select :items="idiomas" label="Idioma" v-model="selectedIdioma" :rules="[v => !!v || 'Este campo es requerido']"></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select :items="estilosCita" label="Estilo de Cita" v-model="selectedCita"></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Título" v-model="selectedTitulo" :rules="[v => !!v || 'Este campo es requerido']"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Document Content Section -->
      <v-card elevation="2" class="mb-4">
        <v-card-title>Contenido del Documento</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-textarea
                label="Descripción"
                v-model="selectedDescripcion"
              ></v-textarea>
            </v-col>
            
            <!-- generador de indices -->
            <v-col cols="12">
              <indice-creator v-model="selectedIndice"></indice-creator>
            </v-col>

            <v-col cols="12">
              <v-textarea
                label="Bibliografía"
                v-model="selectedBibliografia"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Pricing Section -->
      <v-divider class="my-5" color="primary" :thickness="5"></v-divider>
      <h3 class="text-center my-5">
        Descubre todo lo que incluimos con tu documentación:
        <br><br>
        - Índice original adaptado a tu temática específica.
        <br>
        - Referencias bibliográficas con estilo.
        <br>
        - Soporte directo por email.
        <br><br>
        Resuelve todas tus dudas en <a href="/faq" target="_blank">Preguntas Frecuentes</a>.
      </h3>

      <v-row justify="center">
        <v-col cols="12" md="6" lg="4" v-for="(item, index) in items" :key="index">
          <v-card :class="item.entrega === 'estándar' ? 'standard-card' : 'urgent-card'">
            <v-card-title class="justify-center">{{ item.IDPaginas }} Páginas</v-card-title>
            <v-card-subtitle class="text-center" style="margin-bottom: 20px;">
              Entrega {{ item.entrega }} en {{ item.entrega === 'estándar' ? 'menos de 5 días' : 'menos de 48 horas' }}.
            </v-card-subtitle>

            <v-card-text class="text-center">
              <div style="font-weight: bold; font-size: 24px; margin-bottom: 20px;">Solo {{ item.precio }} €</div>
            </v-card-text>

            <v-card-actions class="justify-center">
              <v-btn :color="item.entrega === 'estándar' ? 'standard-button' : 'urgent-button'" x-large style="font-weight: bold;" @click="comprarTFG(item)">
                COMPRA SEGURA
              </v-btn>
              
            </v-card-actions>    
          </v-card>
        </v-col>

      </v-row>
    </v-form>

    <!-- Diálogo para campos vacíos -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">
          <v-icon color="red" left>mdi-alert-circle</v-icon>
          Campos Vacíos
        </v-card-title>
        <v-card-text>
          Por favor, completa todos los campos obligatorios antes de continuar.
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="dialog = false">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import axios from 'axios';
import IndiceCreator from './IndiceCreator.vue'; // Asumiendo que crearemos este componente en un archivo separado

export default {
  components: {
    IndiceCreator
  },
  data() {
    return {
      items: [
        { IDPaginas: 15, entrega: 'estándar', precio: 60 },
        { IDPaginas: 15, entrega: 'urgente',  precio: 70 },
        { IDPaginas: 25, entrega: 'estándar', precio: 100 },
        { IDPaginas: 25, entrega: 'urgente',  precio: 120 },
        { IDPaginas: 35, entrega: 'estándar', precio: 140 },
        { IDPaginas: 35, entrega: 'urgente',  precio: 170 },
      //  { IDPaginas: 40, entrega: 'estándar', precio: 150 },
      //  { IDPaginas: 40, entrega: 'urgente',  precio: 190 },
        { IDPaginas: 50, entrega: 'estándar', precio: 190 },
        { IDPaginas: 50, entrega: 'urgente',  precio: 240 },
        { IDPaginas: 75, entrega: 'estándar', precio: 270 },
        { IDPaginas: 75, entrega: 'urgente',  precio: 360 },
        { IDPaginas: 100, entrega: 'estándar',precio: 350 },
        { IDPaginas: 100, entrega: 'urgente', precio: 480 }
      ],
      valid: true,
      carreras: [
        "Administración y Dirección de Empresas",
        "Antropología y Evolución Humana",
        "Ciencia Política y de la Administración",
        "Comunicación",
        "Criminología",
        "Derecho",
        "Economía",
        "Filosofía, Política y Economía",
        "Educación Social",
        "Trabajo Social",
        "Historia del Arte",
        "Historia y Geografía",
        "Humanidades",
        "Lengua y Literatura Catalanas",
        "Marketing e investigación de mercados",
        "Relaciones Internacionales",
        "Relaciones Laborales y Ocupación",
        "Sociología",
        "Traducción, Interpretación y Lenguas Aplicadas",
        "Turismo",
      ],
      idiomas: ["Español", "Inglés", "Catalán", "Euskera", "Gallego"],
      estilosCita: ["APA 7"],
      selectedCarrera: '',
      selectedEspecialidad: '',
      selectedIdioma: 'Español',
      selectedCita: 'APA 7',
      selectedTitulo: '',
      selectedDescripcion: '',
      selectedIndice: '',
      selectedBibliografia: '',
      selectedNombre: '',
      selectedMail: '',
      dialog: false, // propiedad para manejar el diálogo
    };
  },
  methods: {
    async comprarTFG(item) {
      if (this.$refs.form.validate()) {
        if (!this.selectedNombre || !this.selectedMail || !this.selectedCarrera || !this.selectedTitulo) {
          this.dialog = true;
          return;
        }

        const fechaActual = new Date().toISOString().split('T')[0];

        const formData = new FormData();
        formData.append('nombre', this.selectedNombre);
        formData.append('email', this.selectedMail);
        formData.append('carrera', this.selectedCarrera);
        formData.append('especialidad', this.selectedEspecialidad);
        formData.append('idioma', this.selectedIdioma);
        formData.append('titulo', this.selectedTitulo);
        formData.append('descripcion', this.selectedDescripcion);
        formData.append('estilocita', this.selectedCita);
        formData.append('extension', item.IDPaginas);
        formData.append('entrega', item.entrega);
        formData.append('precio', item.precio);
        formData.append('indice', this.selectedIndice);
        formData.append('bibliografia', this.selectedBibliografia);
        formData.append('creado', fechaActual);
        formData.append('completado', fechaActual);
        formData.append('estado', '1');
        formData.append('pagado', 'no');

        try {
          const response = await axios.post('/api/add.php', formData);

          // Redirigir a la página de confirmación
          this.$router.push('/confirmation');

          this.resetForm();

        } catch (error) {
          alert('Error al enviar el formulario.');
        }
      } else {
          this.dialog = true;
      }
    },
    resetForm() {
      this.selectedCarrera = '';
      this.selectedEspecialidad = '';
      this.selectedIdioma = 'Español';
      this.selectedCita = 'APA 7';
      this.selectedTitulo = '';
      this.selectedDescripcion = '';
      this.selectedIndice = '';
      this.selectedBibliografia = '';
      this.selectedNombre = '';
      this.selectedMail = '';
    }
  }
}
</script>

<style scoped>
.standard-card {
  background-color: #e8f5e9 !important;
  border: 2px solid #388e3c !important;
}

.standard-button {
  background-color: #388e3c !important;
  color: white !important;
}

.urgent-card {
  background-color: #ffebee !important;
  border: 2px solid #d32f2f !important;
}

.urgent-button {
  background-color: #d32f2f !important;
  color: white !important;
}

.v-card-actions {
  display: flex;
  justify-content: center;
}

.headline {
  font-weight: bold;
  text-align: center;
}
</style>