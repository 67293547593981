<template>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="10" lg="8">


          <h1>Soporte de TFG Assistant</h1>

          <section>
              <h2>¿Necesitas Ayuda?</h2>
              <p>Si tienes alguna duda o encuentras algún problema mientras utilizas TFG Assistant, estamos aquí para ayudarte. Utiliza el siguiente formulario para enviarnos tus incidencias o cualquier pregunta que puedas tener. Nos aseguraremos de proporcionarte la asistencia necesaria de manera eficiente y oportuna.</p>

              <v-row justify="center">
                <v-col cols="12" md="10" lg="8" sm="6" >
                  <v-img
                    src="/imagenes/web/Soporte-Tecnico-At-Cliente.jpg"
                    alt="Estudiantes Utilizando TFG Assistant para Proyectos Académicos"
                    title="Estudiantes Utilizando TFG Assistant para Proyectos Académicos"
                    aspect-ratio="1.5"
                  ></v-img>
                </v-col>
              </v-row>



              <h3>Instrucciones para el Formulario de Soporte</h3>
              <p>Por favor, asegúrate de llenar todos los campos requeridos en el formulario. Si tu consulta está relacionada con un trabajo ya existente, es crucial que incluyas el <strong>número de identificación asignado</strong> a tu proyecto. Este número nos permite acceder a los detalles específicos de tu trabajo y acelerar el proceso de soporte.</p>
              
          </section>



          <div class="contactar">
              <contactar-form></contactar-form>
          </div>

          <footer>
              <p>Gracias por utilizar TFG Assistant. Estamos comprometidos a asegurar que tu experiencia sea lo más fluida y productiva posible.</p>
          </footer>
        </v-col>
          </v-row>

    </v-container>
  </template>
  
  <script>
import ContactarForm from '@/components/ContactarForm.vue';

  export default {
    name: 'App',

    components: {
        ContactarForm
    },

    metaInfo: {
        title: 'Soporte - TFG Assistant',
        meta: [
            { charset: 'UTF-8' },
            { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
            { name: 'description', content: 'Obtén ayuda y soporte para utilizar TFG Assistant en la elaboración de tus trabajos finales de grado y máster. Contacto directo y recursos útiles a tu disposición.' },
            { name: 'keywords', content: 'Soporte TFG Assistant, Ayuda TFG, Soporte TFM, Asistencia TFG Assistant' },
            { name: 'author', content: 'TFG Assistant' },
            { name: 'robots', content: 'index, follow' },
            { name: 'googlebot', content: 'index, follow' },
          //  { property: 'og:title', content: 'Soporte - TFG Assistant' },
          //  { property: 'og:description', content: 'Obtén ayuda y soporte para utilizar TFG Assistant en la elaboración de tus trabajos finales de grado y máster. Contacto directo y recursos útiles a tu disposición.' },
          //  { property: 'og:type', content: 'website' },
          //  { property: 'og:url', content: 'https://www.tfgassistant.com/soporte' },
          //  { property: 'og:image', content: 'https://www.tfgassistant.com/images/support-page-banner.jpg' }
        ]
    }



  };
  </script>