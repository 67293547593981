import Vue from 'vue';
import Router from 'vue-router';
import HomeView from '../views/HomeView.vue';
import FormularioCompra from '../views/FormularioCompra.vue';
import Ejemplos from '../views/Ejemplos.vue';
import Soporte from '../views/Soporte.vue';
import ComoFunciona from '../views/ComoFunciona.vue';
import SobreNosotros from '../views/SobreNosotros.vue';
import PagoCompletado from '../views/PagoCompletado.vue';
import Condiciones from '../views/Condiciones.vue';
import Privacidad from '../views/Privacidad.vue';
import Contactar from '../views/contactar.vue';
import serviciosOfrecidos from '../views/ServiciosOfrecidos.vue';
import FAQ from '../views/faq.vue';
import ListaPrecios from '../views/listaprecios.vue';
import PoliticaCookies from '../views/PoliticaCookies.vue';
import ConfirmationPage from '@/components/ConfirmationPage.vue';

Vue.use(Router);

// Crea una instancia nueva del Router.
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: { title: 'TFG ASSISTANT' },
    },
    {
      path: '*',
      name: 'NotFound',
      component: () => import('../components/NotFound.vue')
    },
    {
      path: '/formulario-compra',
      name: 'formularioCompra',
      component: FormularioCompra,
      meta: { title: 'TFG ASSISTANT - COMPRAR' },
    },
    {
      path: '/ejemplos',
      name: 'ejemplos',
      component: Ejemplos,
      meta: { title: 'TFG ASSISTANT - EJEMPLOS' },
    },
    {
      path: '/soporte',
      name: 'soporte',
      component: Soporte,
      meta: { title: 'TFG ASSISTANT - SOPORTE' },
    },
    {
      path: '/como-funciona',
      name: 'comofunciona',
      component: ComoFunciona,
      meta: { title: 'TFG ASSISTANT - COMO FUNCIONA' },
    },
    {
      path: '/sobre-nosotros',
      name: 'sobrenosotros',
      component: SobreNosotros,
      meta: { title: 'TFG ASSISTANT - SOBRE NOSOTROS' },
    },
    {
      path: '/pagocompletado',
      name: 'pagocompletado',
      component: PagoCompletado,
      meta: { title: 'TFG ASSISTANT - PAGO COMPLETADO' },
    },
    {
      path: '/condiciones',
      name: 'condiciones',
      component: Condiciones,
      meta: { title: 'TFG ASSISTANT - Garantías y condiciones de uso' },
    },
    {
      path: '/privacidad',
      name: 'privacidad',
      component: Privacidad,
      meta: { title: 'TFG ASSISTANT - Política de Privacidad' },
    },
    {
      path: '/contactar',
      name: 'contactar',
      component: Contactar,
      meta: { title: 'TFG ASSISTANT - Contactar con nosotros' },
    },
    {
      path: '/serviciosOfrecidos',
      name: 'serviciosOfrecidos',
      component: serviciosOfrecidos,
      meta: { title: 'TFG ASSISTANT - Servicios Ofrecidos' },
    },

    {
      path: '/faq',
      name: 'FAQ',
      component: FAQ,
      meta: { title: 'TFG ASSISTANT - FAQ' },
    },

    {
      path: '/lista-de-precios',
      name: 'ListaPrecios',
      component: ListaPrecios,
      meta: { title: 'TFG ASSISTANT - Precios' },
    },

    {
      path: '/politica-cookies',
      name: 'PoliticaCookies',
      component: PoliticaCookies,
      meta: { title: 'TFG ASSISTANT - Precios' },
    },    
    {
      path: '/confirmation',
      name: 'ConfirmationPage',
      component: ConfirmationPage
    },
 

  ],

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },

});

// Usa el hook afterEach para cambiar el título de la página tras cada cambio de ruta.
router.afterEach((to) => {
  // Cambia el título del documento utilizando el meta campo 'title' de la ruta.
  document.title = to.meta.title || 'TFG ASSISTANT';
});

// Exporta la instancia modificada del Router.
export default router;