<template>
  <v-snackbar v-model="show" color="primary" timeout="0" multi-line bottom>
    <template v-slot:action="{ attrs }">
      <div class="d-flex flex-column justify-center">
        <div>
          Usamos cookies para mejorar su experiencia. Al continuar navegando, acepta nuestro uso de cookies.
        </div>
        <v-btn class="mt-2" color="secondary" text @click="acceptCookies" v-bind="attrs">Aceptar</v-btn>
        <v-btn class="mt-2" color="primary" outlined small @click="openPolicy">Ver Política</v-btn>
      </div>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: !this.cookiesAccepted()
    }
  },
  methods: {
    cookiesAccepted() {
      return localStorage.getItem('cookiesAccepted') === 'true';
    },
    acceptCookies() {
      localStorage.setItem('cookiesAccepted', 'true');
      this.show = false;
    },
    openPolicy() {
      this.$router.push({ path: '/politica-cookies' });
    }
  }
}
</script>

<style scoped>
/* Añade estilos personalizados si es necesario */
</style>