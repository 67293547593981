<template>

    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="10" lg="8">

          <h1>Bienvenido a TFG Assistant: Tu Asistente de Redacción Académica</h1>

            <p>En <strong>TFG Assistant</strong>, transformamos el proceso de escritura de tu Trabajo Final de Grado (TFG) o Trabajo Final de Máster (TFM) utilizando la tecnología más avanzada en inteligencia artificial proporcionada por OpenAI. Nuestro servicio se dirige a estudiantes, redactores y académicos que buscan un apoyo confiable y de alta calidad para estructurar, redactar y refinar sus documentos académicos.</p>
          
              <!-- iamgen de incio -->
              <v-row justify="center">
                <v-col cols="12" md="10" lg="8" sm="6" >
                  <v-img
                    src="/imagenes/web/Home-Inicio-IA-PC-libros-hombre-mujer.jpg"
                    alt="Estudiantes utilizando TFG Assistant en la biblioteca, visualizando y comprobando el resultado de su TFG / TFM"
                    title="Estudiantes Utilizando TFG Assistant para Proyectos Académicos"
                    aspect-ratio="1.5"
                  ></v-img>
                </v-col>
              </v-row>


            <h2>¿Cómo Te Ayudamos?</h2>
              <ol>          
                <li><strong>Tecnología de Vanguardia:</strong> Utilizamos el modelo GPT-4 de OpenAI, la última innovación en IA, para garantizar que cada trabajo esté bien fundamentado, sea original y cumpla con los estándares académicos más elevados.</li>          
                <li><strong>Personalización Completa:</strong> Tú controlas el proceso. Proporciona detalles como la carrera, especialidad, título del trabajo, estilo de cita preferido, índice y bibliografía. Nuestro sistema está diseñado para adaptarse por completo a tus necesidades específicas.</li>        
                <li><strong>Calidad y Originalidad:</strong> Cada documento es redactado desde cero, asegurando originalidad. Los trabajos son meticulosamente revisados para superar cualquier software antiplagio, incluido Turnitin, sin ser detectados por detectores de IA.</li>          
                <li><strong>Facilidad y Accesibilidad:</strong> Desde la comodidad de tu hogar, y en unos pocos clics, puedes configurar y recibir tu TFG o TFM. Simplemente ingresa tus datos y nosotros nos encargamos del resto, entregándote un borrador listo para revisión.</li>
              </ol>


              <!-- Características Principales -->
          <v-row justify="center">
            <v-col cols="12" md="10" lg="8" sm="6" >
              <v-img
                src="/imagenes/web/Inteligencia-Artificial-manos-humanas.jpg"
                alt="Diagrama ilustrativo mostrando los pasos del proceso de TFG Assistant, desde la entrada de datos hasta la generación de texto"
                title="Diagrama de Funcionamiento de TFG Assistant: De la Idea al Documento Final"
                aspect-ratio="1.5"
              ></v-img>
            </v-col>
          </v-row>


            <h2>¿Qué Ofrecemos Concretamente?</h2>
              <ul>
                <li><strong>Redacción del Contenido Completo:</strong> Basándonos en los datos que nos proporcionas, redactamos tu TFG o TFM cumpliendo íntegramente con los requisitos académicos exigidos.</li>
                <li><strong>Diseño de Índice Personalizado:</strong> Puedes optar por que nuestro sistema genere un índice basado en tu información o diseñarlo a medida para reflejar los puntos clave de tu trabajo.</li>
                <li><strong>Gestión de Bibliografía:</strong> Facilitamos un espacio donde puedes añadir las fuentes que desees incorporar, y nos encargamos de integrarlas correctamente en el texto.</li>
              </ul>

            <h2>¿Por Qué Elegir TFG Assistant?</h2>
              <p>Con TFG Assistant, no solo ahorras tiempo y evitas el estrés que implica la redacción de trabajos complejos, sino que también recibes un servicio que se ajusta a tus necesidades y supera tus expectativas. Nuestros borradores no están destinados a ser presentados directamente; ofrecen una base sólida y refinada sobre la que puedes trabajar para personalizar y completar tu proyecto final.</p>
              <p>Cada trabajo es entregado en formato Word, con un estilo claro y profesional (Arial 12 pt., interlineado 1.5 pt., márgenes normales, y separación entre párrafos de 6 pt.) e incluye un índice estructurado con vínculos y un apartado detallado de bibliografía.</p>
            
            <h2>Empieza Ahora</h2>
              <p>Si estás listo para llevar tu TFG o TFM al siguiente nivel, <a href="/formulario-compra">comienza aquí</a> con TFG Assistant. Ingresa tus detalles y deja que nuestra tecnología de IA haga el resto. ¿Tienes preguntas? Visita nuestra <a href="/faq">sección de FAQ</a> o <a href="/contactar">contáctanos</a> directamente para más información.</p>
              
              <h3>Beneficios Destacados de TFG Assistant</h3>
                <p>En TFG Assistant, estamos comprometidos con ofrecerte un servicio excepcional que no sólo cumpla tus expectativas académicas, sino que también te brinde múltiples ventajas que transformarán tu experiencia de redacción académica. Aquí están los beneficios destacados de utilizar nuestro servicio:</p>
              <ol>
                <li>
                <p><strong>Ahorro de Tiempo Significativo:</strong> Con TFG Assistant, el proceso de compilar, estructurar y escribir tu TFG o TFM se simplifica enormemente. No necesitas pasar horas investigando o escribiendo; nosotros lo hacemos por ti. Esto te libera tiempo valioso que puedes dedicar a otras áreas de tus estudios o a tu vida personal.</p>
                </li>
                <li>
                <p><strong>Reducción de Costes:</strong> Nuestro servicio te ofrece una alternativa económica comparada con contratar a redactores académicos tradicionales. Además, evitas costes adicionales de tutorías o revisiones extensas ya que nuestro sistema asegura una calidad que necesita menos correcciones.</p>
                </li>
                <li>
                <p><strong>Resultado de Calidad Garantizado:</strong> Gracias a la avanzada tecnología de IA de OpenAI, cada documento está redactado con un alto estándar de calidad. Aseguramos que el contenido no solo sea original, sino también coherente y bien estructurado, cumpliendo con los requisitos académicos más exigentes.</p>
                </li>
                <li>
                <p><strong>Independencia en la Redacción:</strong> TFG Assistant te proporciona la libertad de no depender de terceros que puedan incumplir plazos o comprometer la calidad de tu trabajo. Tienes el control total sobre la información suministrada y los tiempos de entrega, mitigando así cualquier riesgo de retrasos o problemas.</p>
                </li>
                <li>
                <p><strong>Flexibilidad y Personalización:</strong> Nuestra plataforma te permite personalizar tu pedido introduciendo requisitos específicos como el estilo de cita, índice personalizado y fuentes bibliográficas. Este nivel de personalización asegura que el documento final refleje completamente tus necesidades y expectativas.</p>
                </li>
                <li>
                <p><strong>Confidencialidad y Seguridad:</strong> Mantenemos la máxima discreción y seguridad con todos los datos proporcionados. Tu información y documentos son tratados con la más estricta confidencialidad, asegurando que tu trabajo académico y datos personales están protegidos en todo momento.</p>
                </li>
                <li>
                <p><strong>Soporte Continuo:</strong> Nuestro equipo de atención al cliente está disponible para resolver cualquier consulta o problema que puedas tener, asegurando una experiencia sin complicaciones desde el inicio hasta la entrega del documento final.</p>
                </li>
                <li>
                <p><strong>Preparado para Revisiones:</strong> Si bien suministramos un borrador que requiere revisión final humana, este documento es completo y detallado, sirviendo como una base sólida sobre la cual puedes construir y personalizar aún más el contenido según tus necesidades o prefrencias.</p>
                </li>
              </ol>
              <p>Al elegir TFG Assistant, no solo estás invirtiendo en un servicio de redacción, sino que también estás adquiriendo una herramienta poderosa diseñada para hacer tu camino hacia el éxito académico más fácil, económico y confiable. Haz clic <a href="/formulario-compra">aquí</a> para iniciar tu proyecto con nosotros y descubre la diferencia que TFG Assistant puede hacer en tu educación.</p>
              
            

            <h2>Nuestra Promesa</h2>
              <p>En TFG Assistant, nos comprometemos a proporcionar solo la mejor calidad y el más alto nivel de servicio a nuestros clientes. Si estás listo para liberarte del estrés, gestionar tu tiempo y conseguir la redacción académica que necesitas, únete a nosotros hoy y comprueba por ti mismo por qué nuestros clientes nos eligen y nos recomiendan.</p>
            
            <h2>Únete a Nuestra Comunidad Satisfecha</h2>
              <p>Si estás listo para llevar tu trabajo académico al siguiente nivel, <a href="/contactar">contáctanos</a> hoy y descubre cómo TFG Assistant puede ayudarte a lograr la excelencia académica. ¡Estamos aquí para asistirte en cada paso del camino!</p>


        </v-col>
      </v-row>
 


      <v-divider
        :inset="true"
        class="my-5"
        color="deep-purple accent-4"
        :thickness="5"
      ></v-divider>

<!--  CARGAR OTROS FOMULARIOS-->

      <ComoFunciona/>

      <v-divider
        :inset="true"
        class="my-5"
        color="deep-purple accent-4"
        :thickness="5"
      ></v-divider>

      <Ejemplos/>

      <v-divider
        :inset="true"
        class="my-5"
        color="deep-purple accent-4"
        :thickness="5"
      ></v-divider>

      <FormularioCompra/>         

      <v-divider
        :inset="true"
        class="my-5"
        color="deep-purple accent-4"
        :thickness="5"
      ></v-divider>

   </v-container>
</template>

<script>
import FormularioCompra from './FormularioCompra.vue';
import Ejemplos from './Ejemplos.vue';
import ComoFunciona from './ComoFunciona.vue';
import Soporte from './Soporte.vue';


export default {
  name: "HomeView",
  components: {
    FormularioCompra,
    Ejemplos,
    ComoFunciona,
    Soporte,   
    
  },

  title: '¡NOVEDAD! Redacción Académica TFG / TFM con TFG ASSISTANT',
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: 'Modelo GPT-4 de OpenAI entrenado para redacción personalizada de TFG/TFM. Garantizamos trabajos completos, entregados en 24 horas, libres de plagio y detectores Anti-AI.' },
      { name: 'keywords', content: 'generar texto, Servicio redacción TFG, asistencia TFG / TFM, elaboración proyecto grado, ayuda personalizada TFG / TFM, documento DOCX TFG / TFM, proyecto final universidad asistencia, soporte integral redacción académica, contenido original TFG, calidad asegurada TFG / TFM, entrega rápida proyecto grado, asesoría TFG / TFM personalizada, redacción sin plagio TFG.' },
      // Robots
      { name: 'robots', content: 'index, follow' },
      // Open Graph / Facebook
      //{ property: 'og:title', content: 'El título de tu página' },
      //{ property: 'og:site_name', content: 'El nombre de tu sitio' },
      //{ property: 'og:description', content: 'Descripción de tu página.' },
      //{ property: 'og:type', content: 'website' },
      //{ property: 'og:image', content: 'URL de tu imagen destacada' },
      // Twitter Card
      //{ name: 'twitter:card', content: 'summary_large_image' },
      //{ name: 'twitter:title', content: 'El título de tu página' },
      //{ name: 'twitter:description', content: 'Descripción de tu página.' },
      //{ name: 'twitter:image', content: 'URL de tu imagen destacada' },
    ]
};
</script>
<style>
    h1 {
        margin-bottom: 20px;
    }

    p {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    h2 {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    h3 {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    li {
        margin-top: 15px;
        margin-bottom: 15px;
    }

</style>