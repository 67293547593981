<template>
  <v-container ref="form">

    <v-row justify="center">
      <v-col cols="12" md="10" lg="8">

        <h1>Comprar TFG / TFM Prediseñado por TFG Assistant</h1>
        <h2>¡Asegura tu Éxito Académico con TFG Assistant!</h2>
        
        <p>Estás a solo un paso de obtener un Trabajo de Fin de Grado (TFG) o de Fin de Máster (TFM) de <strong>calidad superior</strong>. Nuestro servicio está diseñado para ser intuitivo, accesible y completamente seguro. Con TFG Assistant, llevamos tu investigación al siguiente nivel.</p>

        <v-row justify="center">
          <v-col cols="12" md="10" lg="8" sm="6" >
            <v-img
              src="/imagenes/web/Formulario-Comprar-TFG-TFM.jpg"
              alt="Pasos del proceso de TFG Assistant, desde la entrada de datos hasta la generación de texto"
              title="Funcionamiento de TFG Assistant: De la Idea al Documento Final"
              aspect-ratio="1.5"
            ></v-img>
          </v-col>
        </v-row>
        
        <p>Proporciona algunos datos para que podamos personalizar tu experiencia y ofrecer un servicio único. Nos comprometemos a manejar tu información de manera confidencial.</p>
        <p>Cuéntanos más sobre tu proyecto: la temática, los requisitos específicos y cualquier detalle relevante que nos permita asignarte el asistente ideal para tu trabajo.</p>
        
        <v-divider
          :inset="true"
          class="my-5"
          color="deep-purple accent-4"
          :thickness="5"
        ></v-divider>

        <div id="app">
          <buy-form></buy-form>
        </div>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// Verifica que la ruta y el nombre estén correctos
import BuyForm from '@/components/BuyForm.vue';


export default {
  name: "FormularioCompra",

  components: {
    BuyForm, // Asegúrate de que el nombre coincida aquí también
    
  },


  data() {
    return {
    }
  },
  metaInfo: {
  title: '¡NOVEDAD! Comprar o Encargar TFG / TFM | Redactado por GPT-4',
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: 'Comprar borrador TFG / TFM redactado por nuestro modelo entrenado de GPT-4 de OpenAI. Textos originales, libres de plagio y adaptados a tus necesidades. Envío en formato Word, listo para personalizar.' },
      { name: 'keywords', content: 'comprar TFG, comprar TFM, encargar TFG, encargar TFM, GPT TFG, GPT TFM, GPT-4 TFG, ayuda TFG / TFM IA, elaboración TFG, OpenAI TFG, asesoramiento académico, redacción TFG / TFM sin plagio, TFG / TFM original, servicio redacción TFG, comprar TFG, TFG / TFM rápido, TFG / TFM calidad, finalizar TFG, TFG / TFM universidad, soporte académico TFG, proyecto grado asistencia, TFG / TFM online, entrega TFG / TFM 24 horas' },
      // Robots
      { name: 'robots', content: 'index, follow' },
      // Open Graph / Facebook
      //{ property: 'og:title', content: 'El título de tu página' },
      //{ property: 'og:site_name', content: 'El nombre de tu sitio' },
      //{ property: 'og:description', content: 'Descripción de tu página.' },
      //{ property: 'og:type', content: 'website' },
      //{ property: 'og:image', content: 'URL de tu imagen destacada' },
      // Twitter Card
      //{ name: 'twitter:card', content: 'summary_large_image' },
      //{ name: 'twitter:title', content: 'El título de tu página' },
      //{ name: 'twitter:description', content: 'Descripción de tu página.' },
      //{ name: 'twitter:image', content: 'URL de tu imagen destacada' },
    ]
  },



}
</script>
