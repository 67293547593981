<template>
    
    <v-container>

      <v-row justify="center">
        <v-col cols="12" md="10" lg="8">

          <h1>Contactar</h1>
      
          <!-- iamgen  -->
          <v-row justify="center">
            <v-col cols="12" md="10" lg="8" sm="6" >
              <v-img
                  src="/imagenes/web/Contactar.jpg"
                  alt="Imagen del formulario de contacto de TFG Assistant"
                  title="Formulario para enviar tus dudas o solicitudes a TFG Assistant"
                  aspect-ratio="2.5"
              ></v-img>
            </v-col>
          </v-row>
          

          <p>En TFG Assistant, estamos comprometidos a proporcionar la mejor experiencia posible a todos nuestros usuarios. Si tienes alguna pregunta, necesitas asistencia o simplemente quieres saber más sobre nuestros servicios, no dudes en ponerte en contacto con nosotros. Aquí encontrarás todas las maneras en que puedes alcanzarnos:</p>
          
          <h2>Información de Contacto</h2>
          <ul>
          <li><strong>Correo Electrónico</strong>:
          <ul>
          <li>Para consultas generales: <a href="mailto:contactar@tfgassistant.com">contactar@tfgassistant.com</a></li>
          <li>Para soporte técnico: <a href="mailto:soporte@tfgassistant.com">soporte@tfgassistant.com</a></li>
          </ul>
          </li>
          <li><strong>Teléfono</strong>:
          <ul>
          <li>Escribe o llama a nuestro número de atención al cliente: +34 644 717 336 (disponible de lunes a viernes de 9:00 a 18:00 CET)</li>
          </ul>
          </li>
          </ul>
          
          <!-- Formulario -->
          <v-divider
            :inset="true"
            class="my-5"
            color="deep-purple accent-4"
            :thickness="5"
          ></v-divider>
          
          <h2>Formulario de Contacto</h2>
          <p>Completa el siguiente formulario con tus datos y tu consulta, y uno de nuestros representantes se pondrá en contacto contigo lo antes posible:</p>

<!---->
          
          <div class="contactar">
              <contactar-form></contactar-form>
          </div>


          <!-- Formulario -->
          <v-divider
            :inset="true"
            class="my-5"
            color="deep-purple accent-4"
            :thickness="5"
          ></v-divider>



          <h2>¿Por qué contactarnos?</h2>
          <p>En TFG Assistant, valoramos cada interacción y nos esforzamos por construir relaciones duraderas con nuestros usuarios. Ya sea que necesites ayuda con nuestro servicio, tengas sugerencias para mejorar o quieras colaborar con nosotros, estamos aquí para escucharte y asistirte de la mejor manera posible.</p>
          <p>Esperamos tener noticias tuyas pronto y ayudarte a alcanzar tus objetivos académicos con la ayuda de TFG Assistant. ¡Contáctanos hoy!</p>


        </v-col>
      </v-row>
    </v-container>
  </template>
  

<script>
import ContactarForm from '@/components/ContactarForm.vue';


export default {

    name: 'contactar',

    components: {
        ContactarForm
    },

    metaInfo: {
      title: 'Contacta con TFG Assistant',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'description', content: 'Página de contacto de TFG Assistant. Utiliza nuestro formulario para cualquier duda o cuestión relacionada con nuestros servicios.' },
        { name: 'keywords', content: 'TFG Assistant, contacto, ayuda, soporte, servicio al cliente' },
        // Robots
        { name: 'robots', content: 'index, follow' },
        // Open Graph / Facebook
        //{ property: 'og:title', content: 'El título de tu página' },
        //{ property: 'og:site_name', content: 'El nombre de tu sitio' },
        //{ property: 'og:description', content: 'Descripción de tu página.' },
        //{ property: 'og:type', content: 'website' },
        //{ property: 'og:image', content: 'URL de tu imagen destacada' },
        // Twitter Card
        //{ name: 'twitter:card', content: 'summary_large_image' },
        //{ name: 'twitter:title', content: 'El título de tu página' },
        //{ name: 'twitter:description', content: 'Descripción de tu página.' },
        //{ name: 'twitter:image', content: 'URL de tu imagen destacada' },
      ]
    },
}

</script>